import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ArrowBackIos as ArrowBackIcon } from '@material-ui/icons';
import { Button, TextField, MenuItem } from '@material-ui/core';
import style from './style.scss';

const SubCategoryHeader = ({
  mainCategories,
  selectedMainCategory,
  onSelectMainCategory,
}) => {
  const {
    report: {
      dateRange: { startDate, endDate },
    },
  } = useSelector((state) => state);
  return (
    <>
      <Button
        size="small"
        color="primary"
        onClick={() => onSelectMainCategory(null)}
        startIcon={<ArrowBackIcon />}
      >
        back
      </Button>
      <div className={style.header}>
        <div className={style.content}>
          <div>
            {selectedMainCategory.mainCategoryName} -{' '}
            {selectedMainCategory.total} ({selectedMainCategory.percentage})
          </div>
          <Link
            to={`/feedback?startDate=${startDate}&endDate=${endDate}&mainCategoryId=${selectedMainCategory.mainCategoryId}`}
          >
            VIEW FEEDBACK
          </Link>
        </div>
        <TextField
          classes={{ root: style.select }}
          select
          label="Main Category"
          value={selectedMainCategory}
          variant="outlined"
          onChange={(e) => onSelectMainCategory(e.target.value)}
        >
          {mainCategories.map((mainCategory) => (
            <MenuItem key={mainCategory.mainCategoryId} value={mainCategory}>
              {mainCategory.mainCategoryName}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </>
  );
};

const mainCategoryPropType = {
  mainCategoryId: PropTypes.string.isRequired,
  mainCategoryName: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  percentage: PropTypes.string.isRequired,
};

SubCategoryHeader.propTypes = {
  mainCategories: PropTypes.arrayOf(PropTypes.shape(mainCategoryPropType)),
  selectedMainCategory: PropTypes.shape(mainCategoryPropType),
  onSelectMainCategory: PropTypes.func.isRequired,
};

export default SubCategoryHeader;
