/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Button, TextField, CircularProgress } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { useFetch } from '@tkww/shared';
import { Auth, User } from '@tkww/client';
import style from './style.scss';

export const AccountActivation = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [isValidToken, setIsValidToken] = useState(true);

  const [fetchUserByToken, { data: userInfo, error }] = useFetch();
  const [signUpUser, { isLoading, data }] = useFetch();

  const authClient = new Auth();
  const userClient = new User();
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const invitationToken = searchParams.get('invitationToken');

  useEffect(() => {
    if (!userInfo && !error) {
      fetchUserByToken(userClient.getUserByToken({ invitationToken }));
    } else {
      setIsValidToken(error === null);
    }
  }, [userInfo, error]);

  const handleActivateEvent = () => {
    if (password !== confirmPassword) {
      setErrorMsg('Passwords do not match.');
    } else if (password.length < 6) {
      setErrorMsg('Passwords has to be at least 6 character.');
    } else {
      setErrorMsg('');
      signUpUser(
        authClient.signup({
          invitationToken,
          password,
          firstName,
          lastName,
        }),
        { success: { title: 'New password has been set.' }, failure: {} }
      );
    }
  };

  !isLoading &&
    data &&
    setTimeout(() => {
      history.push('/login');
    }, 3000);

  return (
    <div className={style.container}>
      <div className={style.form}>
        <div className={style.title}>AppInsights</div>
        <div className={style['welcome-message']}>
          {!isValidToken ? (
            <div style={{ color: 'red' }}>
              You can not activate your account because your token is invalid or
              expired.
            </div>
          ) : (
            `Hello, ${userInfo ? `${userInfo.email}!` : ''}`
          )}
        </div>
        {isValidToken && (
          <div className={style.hint}>
            Tell us your name and set your password before activate your
            account.
          </div>
        )}
        <TextField
          disabled={!isValidToken}
          className={style.infoInput}
          value={firstName}
          label="First Name"
          onChange={(e) => setFirstName(e.target.value)}
          size="small"
          variant="outlined"
          fullWidth
          autoFocus
        />
        <TextField
          disabled={!isValidToken}
          className={style.infoInput}
          value={lastName}
          label="Last Name"
          onChange={(e) => setLastName(e.target.value)}
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          disabled={!isValidToken}
          className={style.infoInput}
          value={password}
          label="New Password (min 6 characters)"
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          size="small"
          variant="outlined"
          fullWidth
        />
        <TextField
          disabled={!isValidToken}
          error={errorMsg !== ''}
          className={style.infoInput}
          value={confirmPassword}
          label="Confirm New Password"
          onChange={(e) => setConfirmPassword(e.target.value)}
          type="password"
          size="small"
          variant="outlined"
          fullWidth
          helperText={errorMsg}
        />
        <Button
          className={style.submit}
          color="primary"
          disabled={!isValidToken || isLoading || data}
          onClick={handleActivateEvent}
          variant="contained"
          fullWidth
        >
          {(isLoading && <CircularProgress size="24px" />) ||
            (data && 'Success!') ||
            'ACTIVATE MY ACCOUNT'}
        </Button>
      </div>
    </div>
  );
};
