import React, { useState, useEffect } from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { v4 as uuidv4 } from 'uuid';
import isEmpty from 'lodash/isEmpty';
import PanelUpdateForm from '../PanelUpdateForm';
import PanelCreateForm from '../PanelCreateForm';
import style from './style.scss';

const Panel = (props) => {
  const { isLoadingSelected, selectedFeedback } = props;
  const [tempTags, setTempTags] = useState([]);
  const onHandleAddTags = () => {
    const tags = [].concat(tempTags);
    tags.push(uuidv4());
    setTempTags(tags);
  };

  useEffect(() => {
    if (
      !isEmpty(selectedFeedback) &&
      isEmpty(selectedFeedback.tags) &&
      !isLoadingSelected
    ) {
      onHandleAddTags();
    }
  }, [selectedFeedback, isLoadingSelected]);

  return (
    <div className={style['panel-container']}>
      <div className={style['panel-title']}>Tagging Panel</div>
      {!isEmpty(selectedFeedback) &&
        selectedFeedback.tags &&
        selectedFeedback.tags.map((t) => {
          return (
            <PanelUpdateForm
              key={t.id}
              categoryId={t.categoryId}
              feedbackId={selectedFeedback.id}
              tagId={t.id}
              status={t.status}
              platforms={t.issueAppearsOn}
              owner={t.owner}
              isBug={t.isBug}
              toolId={t.toolId}
              notes={t.notes}
              storyId={t.storyId}
            />
          );
        })}
      {!isEmpty(selectedFeedback) &&
        tempTags.map((v) => {
          return (
            <PanelCreateForm
              key={v}
              tempTags={tempTags}
              feedbackId={selectedFeedback.id}
              tagId={v}
              setTempTags={setTempTags}
            />
          );
        })}
      <div className={style['add-container']}>
        <div className={style['add-button']} onClick={onHandleAddTags}>
          <AddCircleIcon color="primary" />
          <span>Add Tag Group</span>
        </div>
      </div>
    </div>
  );
};

export default Panel;
