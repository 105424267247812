import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Joi from 'joi';
import { Project } from '@tkww/client';
import BaseModal from '@tkww/shared/components/BaseModal';
import { Select, TextField, useFetch } from '@tkww/shared';
import { Button } from '@material-ui/core';
import {
  brandOptionsMapping,
  platformOptionsMapping,
  projectPayloadMapping,
} from './helper';
import style from './style.scss';

const platformMapping = {
  iOS: 'appStore',
  Android: 'playStore',
  Web: 'zendesk',
};

const schema = Joi.object({
  brandId: Joi.string().guid(),
  platform: Joi.string().valid('iOS', 'Android', 'Web'),
});

const PlatformModal = (props) => {
  const {
    open,
    setModalOpen,
    brands,
    project: defaultProject,
    setReload,
  } = props;
  const [project, setProject] = useState({});
  const [brandOptions, setBrandOptions] = useState([]);
  const [platformOptions, setPlatformOptions] = useState([]);
  const [handleFetch, { data }] = useFetch();

  useEffect(() => {
    if (data) {
      setModalOpen(false);
      setReload(true);
    }
  }, [data]);

  useEffect(() => {
    setBrandOptions(brandOptionsMapping(brands));
  }, [brands]);

  useEffect(() => {
    if (defaultProject.id) {
      setProject(defaultProject);
      setPlatformOptions(platformOptionsMapping([]));
    } else {
      setProject({});
    }
  }, [defaultProject]);

  const handleChangeBrand = (brandId) => {
    setProject({ ...project, brandId, platform: '' });
    const brand = brands.find((brand) => brand.id === brandId);
    setPlatformOptions(platformOptionsMapping(brand.projects));
  };

  const handleChangePlatform = (platform) => {
    setProject({ ...project, platform });
  };

  const handlePlatformDisabled = () => {
    let disabled = false;
    project.id && (disabled = true);
    !project.brandId && (disabled = true);
    platformOptions.length === 0 && (disabled = true);

    return disabled;
  };

  const handleClickButton = () => {
    const payload = projectPayloadMapping(project);
    const client = new Project();
    if (project.id) {
      handleFetch(client.updateProject(project.id, payload));
    } else {
      handleFetch(
        client.createProject({
          ...payload,
          brandId: project.brandId,
          platform: project.platform,
        })
      );
    }
  };

  return (
    <BaseModal
      open={open}
      onClose={() => setModalOpen(false)}
      title={project.id ? 'Update Platform' : 'Add A Platform to A Brand'}
    >
      <div className={style['input-row']}>
        <Select
          label="Choose A Brand"
          value={project.brandId || ''}
          options={brandOptions}
          onChange={(brandId) => handleChangeBrand(brandId)}
          disabled={!!project.id}
        />
      </div>
      <div className={style['input-row']}>
        <Select
          label="Choose A Platform"
          value={project.platform || ''}
          options={platformOptions}
          onChange={(platform) => handleChangePlatform(platform)}
          disabled={handlePlatformDisabled()}
        />
      </div>
      {['iOS', 'Android'].includes(project.platform) && (
        <div className={style['input-row']}>
          <TextField
            label="APP Id"
            placeholder="APP Id"
            value={
              (project[platformMapping[project.platform]] &&
                project[platformMapping[project.platform]].appId) ||
              ''
            }
            onChange={(appId) =>
              setProject({
                ...project,
                [platformMapping[project.platform]]: {
                  ...project[platformMapping[project.platform]],
                  appId,
                },
              })
            }
          />
        </div>
      )}
      {project.platform === 'Android' && (
        <div className={style['input-row']}>
          <TextField
            label="Client Id"
            placeholder="Client Id"
            value={(project.playStore && project.playStore.clientId) || ''}
            onChange={(clientId) =>
              setProject({
                ...project,
                playStore: { ...project.playStore, clientId },
              })
            }
          />
        </div>
      )}
      {project.platform === 'Android' && (
        <div className={style['input-row']}>
          <TextField
            label="Private Key"
            placeholder="Private Key"
            value={(project.playStore && project.playStore.privateKey) || ''}
            onChange={(privateKey) =>
              setProject({
                ...project,
                playStore: { ...project.playStore, privateKey },
              })
            }
          />
        </div>
      )}
      <div className={style['button-row']}>
        <Button variant="contained" color="primary" onClick={handleClickButton}>
          {project.id ? 'UPDATE' : 'ADD'}
        </Button>
      </div>
    </BaseModal>
  );
};

PlatformModal.propTypes = {
  open: PropTypes.bool,
  setModalOpen: PropTypes.func,
  brands: PropTypes.array,
  project: PropTypes.object,
  setReload: PropTypes.func,
};

export default PlatformModal;
