export const getOptionLabel = (option, param, options) => {
  if (options) {
    const findOpt = options.find((opt) => opt.value === option);
    return findOpt ? findOpt.label : option;
  }
  return option.label;
};

export const getOptionSelected = (option, value) => option.value === value;

export const handleChange = (value, reason, setValue, multiple = false) => {
  if (reason === 'select-option' && multiple) {
    const newValue = value[value.length - 1];
    value.splice(value.length - 1, 1);
    const spliceIndex = value.findIndex((val) => val === newValue.value);
    spliceIndex === -1 && value.push(newValue.value);
  }
  setValue(value);
};
