import React, { useState } from 'react';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { getOptionLabel, getOptionSelected, handleChange } from './actions';
import styles from './styles.scss';

const Suggestion = ({
  options = [],
  label,
  name,
  disabled,
  value,
  setValue,
  multiple,
  focused = false,
}) => {
  const [open, openSet] = useState(false);
  const filterOptions = createFilterOptions({
    stringify: getOptionLabel,
    trim: true,
  });

  const handleAutocompleteChange = (event, value, reason) =>
    handleChange(value, reason, setValue, multiple);

  const renderInput = (params) => (
    <TextField
      {...params}
      focused={focused}
      label={label}
      variant="outlined"
      classes={{ root: styles['text-field'] }}
      onInput={(e) => openSet(e.target.value.trim().length >= 2)}
    />
  );

  const renderTags = (value, getTagProps) =>
    multiple
      ? value.map((val, index) => (
          <Chip
            classes={{ root: styles['auto-complete-tag'] }}
            variant="outlined"
            label={getOptionLabel(val, {}, options)}
            {...getTagProps({ index })}
          />
        ))
      : getOptionLabel(value, {}, options);

  return (
    <>
      <input type="hidden" name={name} value={value} />
      <Autocomplete
        open={open}
        classes={{
          inputRoot: styles['input-container'],
          input: styles['text-field-input'],
        }}
        value={value}
        disabled={disabled}
        multiple={multiple}
        options={options}
        getOptionLabel={getOptionLabel}
        renderInput={renderInput}
        renderTags={renderTags}
        filterOptions={filterOptions}
        onChange={handleAutocompleteChange}
        getOptionSelected={getOptionSelected}
        disableCloseOnSelect
        clearOnBlur
        disableClearable
        autoHighlight
        fullWidth
        limitTags={1}
        onBlur={() => openSet(false)}
      />
    </>
  );
};

export default Suggestion;
