import { cloneDeep } from 'lodash';
import { SECURITY_SERVICE, QA_SECURITY_SERVICE } from '@tkww/client';
import qs from 'querystring';

export const getObjectDiff = (targetObject, baseObject, ignoreKeys = []) => {
  const diffObj = {};
  if (Array.isArray(targetObject)) {
    targetObject.forEach((elem, index) => {
      if (!Array.isArray(diffObj)) {
        diffObj = [];
      }
      diffObj[index] = getObjectDiff(elem, (baseObject || [])[index]);
    });
  } else if (targetObject != null && typeof targetObject == 'object') {
    Object.keys(targetObject).forEach((key) => {
      if (!ignoreKeys.includes(key)) {
        if (Array.isArray(targetObject[key])) {
          let arr = getObjectDiff(targetObject[key], baseObject[key]);
          if (!Array.isArray(arr)) {
            arr = [];
          }
          arr.forEach((elem, index) => {
            if (!Array.isArray(diffObj[key])) {
              diffObj[key] = [];
            }
            diffObj[key][index] = elem;
          });
        } else if (typeof targetObject[key] == 'object') {
          diffObj[key] = getObjectDiff(targetObject[key], baseObject[key]);
        } else if (targetObject[key] != (baseObject || {})[key]) {
          diffObj[key] = targetObject[key];
        } else if (targetObject[key] == (baseObject || {})[key]) {
          delete targetObject[key];
        }
      }
    });
  }
  Object.keys(diffObj).forEach((key) => {
    if (
      typeof diffObj[key] == 'object' &&
      JSON.stringify(diffObj[key]) == '{}'
    ) {
      delete diffObj[key];
    }
  });
  return diffObj;
};

export const percentageDataTransform = (data = []) => {
  let total = 0;

  data.forEach((d) => {
    d.total = Number(d.total);
    total += d.total;
  });

  const lists = cloneDeep(data) || [];
  lists.forEach((l) => {
    l.percentage = ((l.total / total) * 100).toFixed(1) + '%';
  });
  return lists;
};

export const bugOwnerDataTransform = (data = []) => {
  const tagOwner = {};
  const newList = [];
  const lists = cloneDeep(data) || [];

  lists.forEach((l) => {
    const owner = l.tagOwner;
    const tagStatus = l.tagStatus.toLocaleLowerCase();
    if (tagOwner[owner]) {
      tagOwner[owner][tagStatus] = l.total;
    } else {
      tagOwner[l.tagOwner] = {
        [tagStatus]: l.total,
      };
    }
  });

  Object.keys(tagOwner).forEach((key) => {
    const resolvedTotal = tagOwner[key].resolved || 0;
    const unresolvedTotal = tagOwner[key].unresolved || 0;
    const bugTotal = Number(resolvedTotal) + Number(unresolvedTotal);

    newList.push({
      tagOwner: key,
      bug: bugTotal,
      resolved: resolvedTotal,
      unresolved: unresolvedTotal,
    });
  });

  return newList;
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const descendingComparator = (a, b, orderBy) => {
  const aValue = percentageFix(a[orderBy]);
  const bValue = percentageFix(b[orderBy]);

  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
};

const percentageFix = (v) => {
  if (typeof v === 'string' && v.includes('%')) {
    return (Number(v.replace('%', '')) / 100).toFixed(3);
  }

  return v;
};

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const getExportReportUrl = (brand, platforms, requestData) => {
  const HOST =
    process.env.REACT_APP_BUILD_ENV === 'production'
      ? SECURITY_SERVICE
      : QA_SECURITY_SERVICE;
  const filename = `${brand.name}-${platforms.join('-')}-${
    requestData.startDate
  }-${requestData.endDate}`;
  const conditions = qs.stringify(requestData);
  const url = `${HOST}/reports/feedbacks?filename=${filename}&${conditions}`;

  return url;
};
