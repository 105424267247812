import moment from 'moment';
import qs from 'query-string';
import {
  autoCompleteFields,
  boolFields,
  defaultFilter,
  multipleFields,
  stringDataKeys,
} from '@tkww/feedback/constants';

export const getFilters = (filter, userFilters, search = '') => {
  let isDefault = false;
  const filterData = { id: filter, name: 'Untitled', options: {} };
  if (filter) {
    const isDefaultFilterField = defaultFilter[filter.toLowerCase()];
    const found = userFilters.find((f) => f.id === filter);
    if (isDefaultFilterField) {
      filterData.name = filter;
      filterData.options = isDefaultFilterField;
    } else if (found) {
      filterData.id = found.id;
      filterData.name = found.name;
      filterData.options = found.options || filterData.options;
    }
    filterData.create = !isDefaultFilterField && !found;
    isDefault = Boolean(isDefaultFilterField);
  } else {
    filterData.id = search;
    filterData.options = qs.parse(search, { ignoreQueryPrefix: true }) || {};
    filterData.create = true;
  }
  return {
    isDefault,
    filterData: { ...filterData, options: filterFormat(filterData.options) },
  };
};

export const filterFormat = (data = {}) => {
  const mappedData = {};

  multipleFields.forEach((key) => {
    if (typeof data[key] === 'string') {
      data[key] = [data[key]];
    }
    if (Array.isArray(data[key]) && !data[key].length) {
      data[key] = ['-1'];
    }
    mappedData[key] = data[key] || ['-1'];
  });

  autoCompleteFields.forEach((key) => {
    if (typeof data[key] === 'string') {
      data[key] = [data[key]];
    }
    mappedData[key] = data[key] || [];
  });

  boolFields.forEach((key) => {
    mappedData[key] = !Boolean(data[key] === 'false' || !data[key]);
  });
  mappedData.startDate = data.startDate
    ? moment(data.startDate).format('YYYY-MM-DD')
    : moment().subtract(1, 'M').format('YYYY-MM-DD');
  mappedData.endDate = data.endDate
    ? moment(data.endDate).format('YYYY-MM-DD')
    : moment().format('YYYY-MM-DD');

  stringDataKeys.forEach((key) => {
    mappedData[key] = data[key] ? data[key].toString() : '';
  });

  return mappedData;
};
