import moment from 'moment';
import {
  autoCompleteFields,
  boolFields,
  multipleFields,
  stringDataKeys,
} from '@tkww/feedback/constants';
import { filterFormat } from '../Feedback/actions';

export const filterDataFormat = (formEl) => {
  const formData = {};
  autoCompleteFields.forEach((field) => {
    if (!formEl[field].disabled)
      formData[field] = formEl[field].value
        ? formEl[field].value.split(',')
        : [];
  });
  boolFields.forEach((field) => {
    if (!formEl[field].disabled) formData[field] = formEl[field].checked;
  });
  multipleFields.forEach((field) => {
    if (!formEl[field].disabled)
      formData[field] = formEl[field].value
        ? formEl[field].value.split(',')
        : ['-1'];
  });
  stringDataKeys.forEach((field) => {
    if (!formEl[field].disabled) formData[field] = formEl[field].value;
  });

  if (formEl.dateRange && !formEl.dateRange.disabled) {
    const [startDate, endDate] = formEl.dateRange.value.split(' - ');
    formData.startDate = moment(startDate);
    formData.endDate = endDate ? moment(endDate) : moment();
  }

  return filterFormat(formData);
};
