import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import Joi from 'joi';
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  CircularProgress,
} from '@material-ui/core';
import { useFetch } from '@tkww/shared';
import { SHOW_MESSAGE } from '@tkww/shared/constants';
import { Auth } from '@tkww/client';
import { LOGIN_SUCCESS } from '../../constants';
import style from './style.scss';

export const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  useEffect(() => {
    const rememberMe = Boolean(+Cookies.get('rememberMe'));
    setRememberMe(rememberMe);
    if (rememberMe) {
      const { email, password } = Cookies.get();
      setEmail(email || '');
      setPassword(password || '');
    }
  }, []);

  const [handleFetch, { isLoading, data }] = useFetch();
  const auth = new Auth();
  useEffect(() => {
    if (!isLoading && data) {
      dispatch({
        type: LOGIN_SUCCESS,
        data,
      });
      history.push('/');
      data.token && Cookies.set('authToken', data.token);
    }
  }, [isLoading, data, dispatch, history]);

  const handleLoginValidate = async () => {
    const schema = Joi.object({
      email: Joi.string().email({ tlds: { allow: false } }),
      password: Joi.string().min(6),
    });
    try {
      await schema.validateAsync({ email, password });

      return true;
    } catch (error) {
      dispatch({
        type: SHOW_MESSAGE,
        data: { level: 'ERROR_MESSAGE', text: error.message },
      });

      return false;
    }
  };

  const handleLogin = async () => {
    const validateResult = await handleLoginValidate();
    if (!validateResult) return false;

    Cookies.set('rememberMe', +rememberMe);
    if (rememberMe && email && password) {
      Cookies.set('email', email);
      Cookies.set('password', password);
    }

    handleFetch(auth.login({ email, password }), {
      failure: {},
      openLoading: true,
    });
  };

  const handleEnterKey = () => {
    if (window.event.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <div className={style.container} onKeyPress={handleEnterKey}>
      <div className={style.form}>
        <div className={style.title}>AppInsights</div>
        <TextField
          autoFocus
          className={style.email}
          fullWidth
          id="email"
          margin="normal"
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          size="small"
          value={email}
          variant="outlined"
          type="email"
        />
        <TextField
          className={style.password}
          fullWidth
          id="password"
          margin="normal"
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          size="small"
          type="password"
          value={password}
          variant="outlined"
        />
        <Button
          className={style.submit}
          color="primary"
          disabled={isLoading}
          fullWidth
          type="submit"
          variant="contained"
          onClick={handleLogin}
        >
          {isLoading ? <CircularProgress size="24px" /> : 'LOG IN'}
        </Button>
        <div className={style.tools}>
          <FormControlLabel
            className={style['remember-me']}
            control={<Checkbox color="primary" value="remember" />}
            label="Remember me"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
          />
          <Link className={style.forgot} href="/forgot-password">
            Forgot password?
          </Link>
        </div>
      </div>
    </div>
  );
};
