import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Joi from 'joi';
import {
  Container,
  CssBaseline,
  Typography,
  TextField,
  Button,
  Link,
  CircularProgress,
} from '@material-ui/core';
import { Auth } from '@tkww/client';
import { useFetch, sharedConstants } from '@tkww/shared';
import { SHOW_MESSAGE } from '@tkww/shared/constants';
import style from './style.scss';

const fetchOptions = {
  success: {
    title: 'Email sent.',
    text: 'Please check the reset password instructions in your inbox.',
  },
  failure: {
    text: 'Invalid Email or Email hasn’t registered in this system.',
  },
  openLoading: true,
};

export const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const auth = new Auth();
  const [handleFetch, { isLoading }] = useFetch();

  const handleForgotPassword = () => {
    const schema = Joi.object({
      email: Joi.string().email({ tlds: { allow: false } }),
    });
    const result = schema.validate({ email });

    if (result.error) {
      dispatch({
        type: SHOW_MESSAGE,
        data: {
          level: sharedConstants.ERROR_MESSAGE,
          text: result.error.message,
        },
      });
    } else {
      handleFetch(auth.forgotPassword({ email }), fetchOptions);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={style['container']}>
        <Typography component="h1" variant="h5" className={style['title']}>
          Forgot your password?
        </Typography>
        <div className={style['description']} mt={10}>
          Enter your registered Email below to receive password reset
          instructions.
        </div>
        <TextField
          id="email"
          type="email"
          label="Email"
          name="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          variant="outlined"
          className={style['email-input']}
        />
        <Button
          className={style['send']}
          color="primary"
          disabled={isLoading}
          onClick={handleForgotPassword}
          type="submit"
          variant="contained"
        >
          {isLoading ? <CircularProgress size="24px" /> : 'SEND'}
        </Button>
        <Link className={style['back-to-login']} href="/login" variant="body2">
          BACK TO LOGIN
        </Link>
      </div>
    </Container>
  );
};
