import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import {
  IconButton,
  Avatar as MaterialAvatar,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { getUserAvatar } from './helper';
import { Auth } from '@tkww/client';
import { useFetch } from '@tkww/shared';
import style from './style.scss';

const Avatar = ({ user }) => {
  const { firstName = '', lastName = '' } = user;
  const userAvatar = getUserAvatar(firstName, lastName);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [handleFetch, { data: logoutResult }] = useFetch();
  const history = new useHistory();

  useEffect(() => {
    if (logoutResult) {
      history.push('/login');
    }
  }, [logoutResult]);

  const handleOpenProfileMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseProfileMenu = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    const authClient = new Auth();
    handleFetch(authClient.logout());
    setAnchorEl(null);
    Cookies.remove('authToken');
  };

  return (
    <div>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenProfileMenu}
        color="inherit"
      >
        <MaterialAvatar>{userAvatar}</MaterialAvatar>
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleCloseProfileMenu}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </div>
  );
};

Avatar.prototype = {
  user: PropTypes.object,
};

export default Avatar;
