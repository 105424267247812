import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import style from './style.scss';

const BrandList = (props) => {
  const {
    brands,
    setBrand,
    setProject,
    setBrandModalOpen,
    setProjectModalOpen,
  } = props;

  const handleEditBrand = (brand) => {
    setBrandModalOpen(true);
    setBrand(brand);
  };

  const handleEditProject = (project) => {
    setProjectModalOpen(true);
    setProject(project);
  };

  return (
    <TableContainer component={Paper}>
      <Table className={style['table-container']}>
        <TableHead className={style['table-header']}>
          <TableRow>
            <TableCell align={'center'}>Brand</TableCell>
            <TableCell align={'center'}>Platform</TableCell>
            <TableCell align={'center'}>Source Integrated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {brands.map((brand, brandIndex) => (
            <Fragment key={brandIndex}>
              <TableRow>
                <TableCell
                  rowSpan={brand.projects.length || 1}
                  align={'center'}
                  className={style['brand']}
                >
                  <span>{brand.name}</span>
                  <EditIcon
                    color="primary"
                    className={style['edit-icon']}
                    onClick={() => handleEditBrand(brand)}
                  />
                </TableCell>
                <TableCell align={'center'} className={style['platform']}>
                  {(brand.projects[0] && brand.projects[0].platform) || ''}
                </TableCell>
                <TableCell
                  align={'center'}
                  className={style['source-integrated']}
                >
                  {brand.projects.length > 0 && (
                    <>
                      <span>
                        {(brand.projects[0] &&
                          brand.projects[0].integratedSource) ||
                          ''}
                      </span>
                      <EditIcon
                        color="primary"
                        className={style['edit-icon']}
                        onClick={() => handleEditProject(brand.projects[0])}
                      />
                    </>
                  )}
                </TableCell>
              </TableRow>
              {brand.projects.slice(1).map((project, projectIndex) => (
                <TableRow key={projectIndex}>
                  <TableCell align={'center'} className={style['platform']}>
                    {project.platform}
                  </TableCell>
                  <TableCell
                    align={'center'}
                    className={style['source-integrated']}
                  >
                    <span>{project.integratedSource}</span>
                    <EditIcon
                      color="primary"
                      className={style['edit-icon']}
                      onClick={() => handleEditProject(project)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

BrandList.propTypes = {
  brands: PropTypes.array.isRequired,
  setBrand: PropTypes.func,
  setProject: PropTypes.func,
  setBrandModalOpen: PropTypes.func,
  setProjectModalOpen: PropTypes.func,
};

export default BrandList;
