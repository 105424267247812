import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker-cssmodules.min.css';
import { TextField, InputAdornment } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import style from './style.scss';

export const DateRangePicker = ({
  classes = {},
  defaultStartDate,
  defaultEndDate,
  endDate,
  focused,
  name,
  onCalendarClose,
  onDateRangeChange,
  startDate,
}) => {
  const [dateRange, setDateRange] = useState({
    startDate: defaultStartDate || new Date(),
    endDate: defaultEndDate,
  });
  const onChange = (dates) => {
    const [start, end] = dates;
    setDateRange({ startDate: start, endDate: end });
    onDateRangeChange && onDateRangeChange(start, end);
  };

  const handleDateRangeValue = () => {
    const start = startDate || dateRange.startDate;
    const startFormat = start ? moment(start).format('MMM DD, YYYY') : '';
    const end = endDate || dateRange.endDate;
    const endFormat = end ? moment(end).format('MMM DD, YYYY') : '';

    return `${startFormat} - ${endFormat}`;
  };

  const renderCustomInput = () => {
    return (
      <TextField
        focused={focused}
        value={handleDateRangeValue()}
        className={clsx(style['text-field'], classes['text-field'])}
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DateRangeIcon color="primary" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <ArrowDropDownIcon color="primary" />
            </InputAdornment>
          ),
        }}
      />
    );
  };

  return (
    <div className={clsx(style['date-picker-container'], classes.root)}>
      <DatePicker
        onChange={onChange}
        startDate={startDate || dateRange.startDate}
        endDate={endDate || dateRange.endDate}
        customInput={renderCustomInput()}
        shouldCloseOnSelect={false}
        value={handleDateRangeValue()}
        maxDate={new Date()}
        fixedHeight
        selectsRange
        name={name}
        onCalendarClose={onCalendarClose}
      />
    </div>
  );
};

DatePicker.propTypes = {
  defaultStartDate: PropTypes.object,
  startDate: PropTypes.object,
  defaultEndDate: PropTypes.object,
  endDate: PropTypes.object,
  onDateRangeChange: PropTypes.func,
  classes: PropTypes.object,
  focused: PropTypes.bool,
};
