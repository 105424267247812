import React, { useContext, useCallback, useEffect, useState } from 'react';
import { useFetch } from '@tkww/shared';
import { Feedback as FeedbackApi } from '@tkww/client';
import PanelForm from '../PanelForm';
import { FeedbackContext } from '../../context';

const PanelUpdateForm = ({
  isBug,
  owner,
  platforms,
  status,
  feedbackId,
  tagId,
  categoryId,
  toolId,
  notes,
  storyId,
}) => {
  const { refreshSelectedFeedback, setIsLoadingSelected } = useContext(
    FeedbackContext
  );
  const [handlePutTag, { data: resPutData, error: putError }] = useFetch();
  const [
    handleDeletedTag,
    { data: resDeletedData, error: deletedError },
  ] = useFetch();
  const feedbackApi = new FeedbackApi();

  useEffect(() => {
    if (resDeletedData || resPutData) {
      refreshSelectedFeedback(feedbackId);
    }
  }, [resDeletedData, resPutData, feedbackId, refreshSelectedFeedback]);

  useEffect(() => {
    if (putError || deletedError) {
      setIsLoadingSelected(false);
    }
  }, [putError, deletedError]);

  const handleSave = useCallback(
    (payload) => {
      setIsLoadingSelected(true);
      handlePutTag(feedbackApi.putFeedbacksTags(feedbackId, tagId, payload), {
        failure: {},
      });
    },
    [feedbackId, tagId, handlePutTag]
  );

  const handleDelete = useCallback(() => {
    setIsLoadingSelected(true);
    handleDeletedTag(feedbackApi.deleteFeedbacksTags(feedbackId, tagId), {
      failure: {},
    });
  }, [feedbackId, tagId, handleDeletedTag]);

  return (
    <PanelForm
      categoryId={categoryId}
      status={status}
      platforms={platforms}
      owner={owner}
      isBug={isBug}
      toolId={toolId}
      onDelete={handleDelete}
      onSave={handleSave}
      showDelete
      notes={notes}
      storyId={storyId}
    />
  );
};

export default PanelUpdateForm;
