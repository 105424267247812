import React from 'react';
import GradeIcon from '@material-ui/icons/Grade';
import GradeOutlinedIcon from '@material-ui/icons/GradeOutlined';
import style from './style.scss';

const Rating = ({ rating }) => {
  return (
    <>
      {[...Array(5).keys()].map((i) => {
        if (i < rating) {
          return (
            <GradeIcon key={i} style={{ color: '#C5CAE9', fontSize: '15px' }} />
          );
        }
        return (
          <GradeOutlinedIcon
            key={i}
            className={style['hollow']}
            style={{ fontSize: '15px', color: '#C5CAE9' }}
          />
        );
      })}
    </>
  );
};

export default Rating;
