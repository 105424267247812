import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { SET_RELOAD_COLLABORATORS } from '../../constants';
import BaseModal from '@tkww/shared/components/BaseModal';
import { Select, TextField, useFetch } from '@tkww/shared';
import { Button } from '@material-ui/core';
import { User } from '@tkww/client';
import Joi from 'joi';
import style from './style.scss';

const roleOptions = [
  { label: 'User', value: 'Member' },
  { label: 'Admin', value: 'Administrator' },
];

const schema = Joi.object({
  'First Name': Joi.string().max(50),
  'Last Name': Joi.string().max(50),
});

export const EditUserModal = (props) => {
  const {
    setModalDisplay,
    brandId,
    userId,
    role: defaultRole,
    firstName: defaultFirstName,
    lastName: defaultLastName,
  } = props;
  const [role, setRole] = useState(defaultRole);
  const [firstName, setFirstName] = useState(defaultFirstName);
  const [lastName, setLastName] = useState(defaultLastName);
  const [errorText, setErrorText] = useState({});
  const [handleFetch, { data: editResult }] = useFetch();
  const client = new User();
  const dispatch = useDispatch();

  useEffect(() => {
    if (editResult) {
      setModalDisplay(false);
      dispatch({
        type: SET_RELOAD_COLLABORATORS,
        data: { reloadCollaborators: true },
      });
    }
  }, [editResult]);

  const handleClickSave = () => {
    const result = schema.validate({
      'First Name': firstName,
      'Last Name': lastName,
    });
    if (result.error) {
      const errorTextMap = {};
      result.error.details.forEach((item) => {
        errorTextMap[item.context.key] = item.message;
      });
      setErrorText(errorTextMap);
    } else {
      setErrorText({});
      handleFetch(
        client.updateBrandUser(brandId, userId, { role, firstName, lastName })
      );
    }
  };

  return (
    <BaseModal open={true} onClose={() => setModalDisplay(false)}>
      <div className={style['input-row']}>
        <Select
          label="Role"
          value={role}
          options={roleOptions}
          onChange={setRole}
        />
      </div>
      <div className={style['input-row']}>
        <TextField
          label="First Name"
          placeholder="First Name"
          value={firstName}
          onChange={setFirstName}
          errorText={errorText['First Name'] || ''}
        />
      </div>
      <div className={style['input-row']}>
        <TextField
          label="Last Name"
          placeholder="Last Name"
          value={lastName}
          onChange={setLastName}
          errorText={errorText['Last Name'] || ''}
        />
      </div>
      <div className={style['button-row']}>
        <Button variant="contained" color="primary" onClick={handleClickSave}>
          SAVE
        </Button>
      </div>
    </BaseModal>
  );
};

EditUserModal.propTypes = {
  setModalDisplay: PropTypes.func,
  brandId: PropTypes.string,
  userId: PropTypes.string,
  role: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  editResult: PropTypes.func,
};
