import { CHANGE_DATE_RANGE, CHANGE_MAIN_CATEGORIES } from '../constants';

const ACTION_HANDLERS = {
  [CHANGE_DATE_RANGE]: (state, { data }) => ({
    ...state,
    dateRange: data,
  }),
  [CHANGE_MAIN_CATEGORIES]: (state, { data }) => ({
    ...state,
    mainCategories: data.mainCategories,
  }),
};

const initialState = {
  dateRange: {
    startDate: null,
    endDate: null,
  },
  mainCategories: [],
};

export const reportReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
