const sourceMapping = {
  iOS: ['appStore', 'zendesk'],
  Android: ['playStore', 'zendesk'],
  Web: ['zendesk'],
};

const integratedSourceMapping = {
  appStore: 'Apple',
  playStore: 'Google',
  zendesk: 'Zendesk',
};

const platformOptions = [
  { label: 'iOS', value: 'iOS' },
  { label: 'Android', value: 'Android' },
  { label: 'Web', value: 'Web' },
];

export const dataMapping = (brands, projects) => {
  const data = [...brands];
  data.forEach((brand) => {
    brand.projects = [];
    projects.forEach((project) => {
      if (brand.id === project.brandId) {
        brand.projects.push({
          ...project,
          integratedSource: platformIntegratedSourceMapping(project),
        });
      }
    });
  });

  return data;
};

export const platformIntegratedSourceMapping = (project) => {
  const integratedSource = [];
  sourceMapping[project.platform].forEach((item) => {
    if (Object.keys(project[item]).length) {
      integratedSource.push(integratedSourceMapping[item]);
    }
  });

  return integratedSource.join(', ');
};

export const brandOptionsMapping = (data) =>
  data.map((item) => ({
    label: item.name,
    value: item.id,
  }));

export const platformOptionsMapping = (projects) => {
  const platforms = projects.map((project) => project.platform);

  return platformOptions.filter(
    (platform) => !platforms.includes(platform.value)
  );
};

export const projectPayloadMapping = (project) => {
  const payload = {};
  sourceMapping[project.platform].forEach((source) => {
    payload[source] = project[source] || {};
  });

  return payload;
};
