import moment from 'moment';
import qs from 'querystring';

export const categoriesMapping = (categories) =>
  categories.map(({ id, name, children = [] }) => ({
    value: id,
    label: name,
    children: children.map(({ id, name }) => ({ value: id, label: name })),
  }));

export const filterSubmit = (formData) => {
  const newData = { limit: 10, page: 1 };
  Object.keys(formData).forEach((key) => {
    if (key === 'startDate' || key === 'endDate') {
      newData[key] = moment(formData[key]).format('YYYY-MM-DD');
    } else if (
      (typeof formData[key] === 'string' && formData[key]) ||
      (Array.isArray(formData[key]) &&
        formData[key].length > 0 &&
        !formData[key].includes('-1')) ||
      (typeof formData[key] === 'boolean' && formData[key])
    ) {
      newData[key] = formData[key];
    }
  });
  const queryString = qs.stringify(newData);
  window.location.href = `/feedback?${queryString}`;
};

export const osVersionMapping = (osVersionData, projectIds) => {
  const osVersionOptions = [];
  osVersionData.forEach((osVersionObj) => {
    projectIds.includes(osVersionObj.projectId) &&
      osVersionObj.versions.forEach((version) => {
        !osVersionOptions.find((opt) => opt.value == version) &&
          osVersionOptions.push({ value: version, label: version });
      });
  });
  return osVersionOptions;
};

export const appVersionMapping = (appVersionData, projectIds) => {
  const appVersionOptions = [];
  appVersionData.forEach((appVersionObj) => {
    projectIds.includes(appVersionObj.projectId) &&
      appVersionObj.versions.forEach((version) => {
        !appVersionOptions.find((opt) => opt.value == version) &&
          appVersionOptions.push({ value: version, label: version });
      });
  });
  return appVersionOptions;
};

export const toolMapping = (tools = []) =>
  tools
    .map(({ id, name, status }) => ({ value: id, label: name, status }))
    .filter((tool) => tool.status === 'active');

export const labelsMapping = (labels) =>
  labels.map(({ id, name }) => ({
    value: id,
    label: name,
  }));
