import React, { useState } from 'react';
import TabBar from '@tkww/shared/components/TabBar';
import Collaborators from '../Collaborators';
import Categories from '../Categories';
import Tools from '../Tools';
import {
  SETTINGS_TYPE_COLLABORATORS,
  SETTINGS_TYPE_CATEGORIES,
  SETTINGS_TYPE_TOOLS,
} from '../../constants';
import style from './style.scss';

const settingsTabItems = [
  { label: SETTINGS_TYPE_COLLABORATORS, component: <Collaborators /> },
  { label: SETTINGS_TYPE_CATEGORIES, component: <Categories /> },
  { label: SETTINGS_TYPE_TOOLS, component: <Tools /> },
];

const Settings = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleChangeTabBar = (index) => {
    setTabIndex(index);
  };

  return (
    <div className={style.container}>
      <div className={style.header}>
        <span className={style.title}>View by:</span>
        <TabBar
          width="460px"
          items={settingsTabItems}
          selectIndex={tabIndex}
          onChange={handleChangeTabBar}
        />
      </div>
      <div className={style.content}>
        {settingsTabItems[tabIndex].component}
      </div>
    </div>
  );
};

export default Settings;
