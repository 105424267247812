import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from '@material-ui/core';
import { Add, Remove, Description } from '@material-ui/icons';
import { FilterForm } from '../FilterForm';
import { SaveFilter } from '../SaveFilter';
import {
  checkDefaultFieldChanged,
  getClasses,
  handleFilterFormData,
} from './actions';
import { getExportReportUrl } from '@tkww/shared/utils';
import styles from './styles.scss';

export const FilterSection = ({ create, isDefault, filterData }) => {
  const { projectIds, token, brand, platforms } = useSelector(
    (state) => state.auth
  );
  const [formData, setFormData] = useState({ ...filterData.options });
  const [expanded, setExpanded] = useState(!isDefault);
  const [fieldChanged, fieldChangedSet] = useState(
    !create ? {} : { ...checkDefaultFieldChanged(filterData.options) }
  );
  const formRef = useRef();
  const expandedHandler = () =>
    setExpanded(Boolean(isDefault) ? false : !expanded);

  useEffect(() => {
    setExpanded(!isDefault);
  }, [isDefault, filterData.id]);

  useEffect(() => {
    setFormData({ ...filterData.options });
    fieldChangedSet(
      !create ? {} : { ...checkDefaultFieldChanged(filterData.options) }
    );
  }, [create, filterData.options]);

  const handleExportReport = () => {
    window.location.href = getExportReportUrl(brand, platforms, {
      token,
      projectIds,
      ...handleFilterFormData(formData),
    });
  };

  return (
    <div className={styles['filter-section']}>
      <Accordion
        square
        disabled={Boolean(isDefault)}
        expanded={expanded}
        onChange={expandedHandler}
        classes={getClasses.AccordionClasses}
        TransitionProps={{ unmountOnExit: false }}
      >
        <AccordionSummary classes={getClasses.AccordionSummaryClasses}>
          <div className={styles['filter-title']}>
            <span>
              {`${filterData.name}${Boolean(isDefault) ? ' Feedback' : ''}`}
            </span>
            {Object.values(fieldChanged).includes(true) && (
              <>
                <div className={styles['field-changed-container']}>Edit</div>
                <SaveFilter
                  create={create}
                  formRef={formRef}
                  id={filterData.id}
                  name={filterData.name}
                />
              </>
            )}
          </div>
          {!isDefault && (
            <div className={styles['collapse-btn']}>
              <span>
                <Button
                  color="primary"
                  startIcon={<Description />}
                  onClick={handleExportReport}
                >
                  Export
                </Button>
              </span>
              <span className={styles['collapse-span']}>
                {expanded ? 'collapse' : 'expand'}
              </span>
              {expanded ? <Remove /> : <Add />}
            </div>
          )}
        </AccordionSummary>
        <AccordionDetails classes={getClasses.AccordionDetailsClasses}>
          {!isDefault && (
            <FilterForm
              create={create}
              filterData={filterData}
              fieldChanged={fieldChanged}
              fieldChangedSet={fieldChangedSet}
              formRef={formRef}
              projectIds={projectIds}
              formData={formData}
              setFormData={setFormData}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default FilterSection;
