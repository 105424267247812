import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import style from './style.scss';

export const TablePageHeader = (props) => {
  const { children } = props;

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="flex-end"
    >
      {children}
    </Grid>
  );
};
