import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { SUCCESS_MESSAGE, ERROR_MESSAGE, SHOW_MESSAGE } from '../../constants';

const logoutPathMapping = {
  '/login': true,
  '/forgot-password': true,
  '/reset-password': true,
};

const handleErrorResponseMessage = async (response) => {
  const nok = await response.json();
  throw new Error(nok.message);
};

export const useFetch = (initData = null, initLoading = false) => {
  const [isLoading, setIsLoading] = useState(initLoading);
  const [fetchResponse, setFetchResponse] = useState({
    data: initData,
    error: null,
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const httpInterceptor = async (fetcher, options = {}) => {
    const { success, failure, openLoading = false, transformFun } = options;
    openLoading && setIsLoading(true);
    try {
      const response = await fetcher;
      if (!response.ok) {
        switch (response.status) {
          case 401:
            if (!logoutPathMapping[location.pathname]) {
              return history.push('/login');
            }
            await handleErrorResponseMessage(response);
          case 403:
            if (!logoutPathMapping[location.pathname]) {
              return history.push('/');
            }
            await handleErrorResponseMessage(response);
          case 500:
            throw new Error('Server Error');
          default: {
            await handleErrorResponseMessage(response);
          }
        }
      }
      const responseText = await response.text();
      const result = responseText ? JSON.parse(responseText) : {};
      setFetchResponse({
        data: transformFun ? transformFun(result) : result,
        error: null,
      });
      success &&
        dispatch({
          data: {
            title: success.title || '',
            text: success.text || '',
            level: SUCCESS_MESSAGE,
          },
          type: SHOW_MESSAGE,
        });
    } catch (e) {
      const errorMessage = failure && failure.text ? failure.text : e.message;
      setFetchResponse({
        data: initData,
        error: new Error(errorMessage),
      });
      failure &&
        dispatch({
          data: {
            title: failure.title || 'Oops, an error has occurred.',
            text: errorMessage,
            level: ERROR_MESSAGE,
          },
          type: SHOW_MESSAGE,
        });
    }
    openLoading && setIsLoading(false);
  };

  return [httpInterceptor, { isLoading, setIsLoading, ...fetchResponse }];
};
