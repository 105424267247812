import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import {
  Login,
  ForgotPassword,
  ResetPassword,
  AccountActivation,
  withAuthCheck,
} from '@tkww/auth';
import { Dashboard } from '@tkww/dashboard';
import { Message } from '@tkww/shared';
import { Feedback } from '@tkww/feedback';
import { Settings } from '@tkww/settings';
import { Report } from '@tkww/report';
import { SuperAdmin } from '@tkww/super-admin';
import { Layout } from './components';

const Routes = () => {
  const EnhancedLayout = withAuthCheck(Layout);

  return (
    <>
      <Router>
        <Switch>
          <Route exact component={Login} path="/login" />
          <Route exact component={ForgotPassword} path="/forgot-password" />
          <Route exact component={ResetPassword} path="/reset-password" />
          <Route
            exact
            component={AccountActivation}
            path="/account-activation"
          />
          <Route
            path="/"
            render={({ match, history }) => (
              <EnhancedLayout history={history} match={match}>
                <Switch>
                  <Route component={Dashboard} path={'/dashboard'} />
                  <Route component={Report} path={'/statistics'} />
                  <Route
                    path={'/feedback/new-filter'}
                    render={(props) => <Feedback {...props} create />}
                  />
                  <Route component={Feedback} path={'/feedback/:filter'} />
                  <Route component={Feedback} path={'/feedback'} />
                  <Route component={Settings} path={'/settings'} />
                  <Route component={SuperAdmin} path={'/super-admin'} />
                  <Route>
                    <Redirect to="/statistics" />
                  </Route>
                </Switch>
              </EnhancedLayout>
            )}
          />
        </Switch>
      </Router>
      <Message />
    </>
  );
};

export default Routes;
