import React, { useState, useEffect, useRef, memo } from 'react';
import { PieChart as RechartsPieChart, Pie, Cell } from 'recharts';
import _ from 'lodash';

const RADIAN = Math.PI / 180;
const colors = [
  '#4687f1',
  '#da463e',
  '#f2b32c',
  '#209c5b',
  '#fd6f22',
  '#4cbdc5',
  '#a938c1',
  '#c1bb34',
];

const PieChart = (props) => {
  const {
    width,
    height,
    isAnimationActive = false,
    outerRadius,
    data,
    onClickPie,
    hideLabelPercent = 5,
    dataKey = 'value',
    nameKey = 'name',
  } = props;

  const [activeIndex, setActiveIndex] = useState(-1);
  const animationRef = useRef({ isAnimationEnd: false });

  const renderLabel = ({
    cx,
    cy,
    midAngle,
    outerRadius,
    fill,
    percent,
    index,
  }) => {
    if (percent * 100 <= hideLabelPercent) {
      return <></>;
    }
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 50) * cos;
    const my = cy + (outerRadius + 50) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`${data[index][nameKey] || ''}`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {`${(percent * 100).toFixed(1)}%`}
        </text>
      </g>
    );
  };

  const extendColor = (dataLength) => {
    const extendColorNumber =
      dataLength > colors.length ? dataLength - colors.length : 0;
    for (let i = 0; i < extendColorNumber; i++) {
      colors.push('#' + ((Math.random() * 0xffffff) << 0).toString(16));
    }
  };

  const handleAnimationActive = () => {
    animationRef.current.isAnimationEnd = true;
  };

  const handleOverPie = (data, index) => {
    if (animationRef.current.isAnimationEnd) {
      setActiveIndex(index);
    }
  };

  const handleOutPie = () => {
    if (animationRef.current.isAnimationEnd) {
      setActiveIndex(-1);
    }
  };

  const handleClickPie = (pieData, index) => {
    if (isAnimationActive && !animationRef.current.isAnimationEnd) return;
    onClickPie && onClickPie(data[index], index);
  };

  useEffect(() => {
    if (data) {
      extendColor(data.length);
      animationRef.current.isAnimationEnd = false;
    }
  }, [data]);

  return (
    <RechartsPieChart width={width} height={height}>
      <Pie
        cx={width / 2}
        cy={height / 2}
        dataKey={dataKey}
        nameKey={nameKey}
        isAnimationActive={
          isAnimationActive && !animationRef.current.isAnimationEnd
        }
        onAnimationEnd={handleAnimationActive}
        data={data}
        outerRadius={outerRadius}
        label={renderLabel}
        labelLine={false}
        onMouseOver={handleOverPie}
        onMouseOut={handleOutPie}
        onClick={handleClickPie}
      >
        {data &&
          data.map((entry, index) => (
            <Cell
              cursor="pointer"
              key={`cell-${index}`}
              fill={colors[index]}
              fillOpacity={activeIndex === index ? 0.9 : 1}
            />
          ))}
      </Pie>
    </RechartsPieChart>
  );
};

export default memo(
  PieChart,
  (prevPros, nextPros) => prevPros.data === nextPros.data
);
