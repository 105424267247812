import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useFetch } from '@tkww/shared';
import Button from '@material-ui/core/Button';
import { Settings } from '@tkww/client';
import { useSelector } from 'react-redux';
import ToolsEditModal from '../ToolsEditModal';
import style from './style.scss';
import { DataGrid } from '../DataGrid';

const UnArchived = ({ switchArchived, toolStatistics }) => {
  const [open, setOpen] = useState(false);
  const [handleFetchTool, { data: tools }] = useFetch([]);

  const { brand, role } = useSelector((state) => ({
    brand: state.auth.brand,
    role: state.auth.user.role,
  }));

  const getTools = () => {
    const setting = new Settings();
    handleFetchTool(
      setting.getTools({ brandId: brand.id, includeArchived: false })
    );
  };

  useEffect(() => {
    getTools();
  }, [brand]);

  return (
    <div>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-end"
      >
        <div>
          <span className={style['content-total']}>
            {tools.length} tool(s) in this brand
          </span>
          <span className={style['archived-items']} onClick={switchArchived}>
            ARCHIVED ITEMS
          </span>
        </div>
        {role === 'Administrator' && (
          <Button
            className={style['add-button']}
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
          >
            ADD TOOL
          </Button>
        )}
      </Grid>
      <div className={style['data-grid-container']}>
        <DataGrid
          list={tools}
          getTools={getTools}
          toolStatistics={toolStatistics}
        />
      </div>
      {open && (
        <ToolsEditModal
          open={open}
          onClose={() => setOpen(false)}
          getTools={getTools}
        />
      )}
    </div>
  );
};
export default UnArchived;
