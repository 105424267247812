import React, { useEffect, useState, useContext, useMemo } from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import clsx from 'clsx';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import BugReportOutlinedIcon from '@material-ui/icons/BugReportOutlined';
import Checkbox from '@material-ui/core/Checkbox';
import { Feedback as FeedbackApi } from '@tkww/client';

import PropTypes from 'prop-types';
import moment from 'moment';

import isEmpty from 'lodash/isEmpty';
import CircularProgress from '@material-ui/core/CircularProgress';
import Rating from '../Rating';
import Copy from '../Copy';
import { FeedbackContext } from '../../context';
import style from './style.scss';

const FeedbackCard = (props) => {
  const {
    authorName,
    createTime,
    email,
    text,
    application,
    rating,
    userAgent,
    agentVersion,
    device,
    originalFeedbackId,
    handleDrawer,
    id,
    tags = [],
    isOpenPanel,
    isFavorite: initFavorite,
  } = props;
  const [feedbackTags, setFeedbackTags] = useState(tags);
  const [isFavorite, setIsFavorite] = useState(initFavorite);
  const [loading, setLoading] = useState(false);
  const {
    selectedFeedback,
    tools,
    categoriesMapping,
    refreshSelectedFeedback,
  } = useContext(FeedbackContext);
  const feedbackApi = new FeedbackApi();

  useEffect(() => {
    if (
      !isEmpty(selectedFeedback) &&
      selectedFeedback.id === id &&
      selectedFeedback.tags
    ) {
      setFeedbackTags(selectedFeedback.tags);
    }
  }, [selectedFeedback]);

  const onHandleDrawer = () => {
    handleDrawer(props);
  };

  const onChange = async (event) => {
    event.stopPropagation();
    setLoading(true);
    try {
      for (const item of feedbackTags) {
        if (item.isBug) {
          if (item.status === 'Unresolved' && !bugChecked) {
            await feedbackApi.putFeedbacksTags(id, item.id, {
              status: 'Resolved',
              isBug: item.isBug,
            });
          } else if (item.status !== 'Unresolved' && bugChecked) {
            await feedbackApi.putFeedbacksTags(id, item.id, {
              status: 'Unresolved',
              isBug: item.isBug,
            });
          }
        }
      }
      refreshSelectedFeedback(id);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const handleFavorite = async (event) => {
    event.stopPropagation();
    if (isFavorite) {
      await feedbackApi.postFeedbacksUnfavorite(id);
    } else {
      await feedbackApi.postFeedbacksFavorite(id);
    }
    setIsFavorite(!isFavorite);
  };

  const showBug = useMemo(
    () => feedbackTags.findIndex((t) => t.isBug === true) !== -1,
    [feedbackTags]
  );

  const bugChecked = useMemo(
    () =>
      feedbackTags.findIndex(
        (t) => t.isBug === true && t.status === 'Unresolved'
      ) === -1,
    [feedbackTags]
  );

  return (
    <div
      className={clsx(
        style['feedback-container'],
        isOpenPanel && selectedFeedback.id === id && style['selected-feedback']
      )}
      onClick={onHandleDrawer}
    >
      <div className={style.header}>
        <div>
          <span
            className={clsx(
              style['status-label'],
              feedbackTags.length && style.green
            )}
          >
            {feedbackTags.length ? 'Tagged' : 'Pending'}
          </span>
          <span className={style['author-name']}>{authorName}</span>
          {email ? (
            <span className={style.email}>
              {email}
              <Copy copyContent={email} text="copy" />
            </span>
          ) : (
            ''
          )}
        </div>
        <div className={style.time}>
          {moment
            .utc(new Date(createTime), 'MM/DD/YYYY, h:mm A')
            .format('MM/DD/YYYY, h:mm A')}
          {isFavorite ? (
            <VisibilityIcon
              className={style['eye-icon']}
              style={{ color: '#303F9F' }}
              onClick={handleFavorite}
            />
          ) : (
            <VisibilityOutlinedIcon
              className={style['eye-icon']}
              style={{ color: '#303F9F' }}
              onClick={handleFavorite}
            />
          )}
        </div>
      </div>
      <p className={style.content}>{text}</p>
      <div className={style['detail-container']}>
        <div>
          <span className={style.source}>Source: </span>
          <span className={style.application}>{application}</span>
          <span className={style['feeback-id']}>ID: {originalFeedbackId}</span>
        </div>
        <div>
          <span className={style['rating-icon']}>
            <Rating rating={rating} />
          </span>
          <span className={style.rating}>{rating}</span>
        </div>
      </div>
      <div className={style['info-container']}>
        <span className={style['info']}>OS Version: </span>
        <span className={style['info-content']}>{userAgent}</span>
        <span className={style['info']}>APP Version: </span>
        <span className={style['info-content']}>{agentVersion}</span>
        <span className={style['info']}>Device: </span>
        <span className={style['info-content']}>{device}</span>
      </div>
      {feedbackTags.length > 0 && (
        <div className={style['tag-container']}>
          {feedbackTags.map((t) => {
            const tool =
              !isEmpty(tools) && tools.find((item) => t.toolId === item.id);
            return (
              <div className={style['tag-box']} key={t.id}>
                <div className={style['tag-info']}>
                  <div className={style['tag-title']}>Tool</div>
                  <div className={style['tag-content']}>
                    {!isEmpty(tool) && tool.name}
                  </div>
                </div>
                <div className={style['tag-info']}>
                  <div className={style['tag-title']}>Platform</div>
                  <div className={style['tag-content']}>
                    {t.issueAppearsOn[0]}
                  </div>
                </div>
                <div className={style['tag-info']}>
                  <div className={style['tag-title']}>Category</div>
                  <div className={style['tag-content']}>
                    {categoriesMapping[t.categoryId] &&
                      categoriesMapping[t.categoryId].name}
                  </div>
                </div>
              </div>
            );
          })}
          {showBug && (
            <div className={style['bug-container']}>
              <div className={clsx(style['bug-box'])}>
                <BugReportOutlinedIcon />
                <div
                  className={style['check-box']}
                  onClick={(e) => e.stopPropagation()}
                >
                  Solved?
                  <Checkbox
                    onChange={onChange}
                    checked={bugChecked}
                    disabled={loading}
                    className={style['checkbox-icon']}
                    color="primary"
                  />
                </div>
                {loading && (
                  <div className={style['loading-container']}>
                    <CircularProgress size={24} className={style.loading} />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      <div className={style['icon-container']}>
        <CreateOutlinedIcon />
      </div>
    </div>
  );
};

FeedbackCard.propTypes = {
  authorName: PropTypes.string,
  createTime: PropTypes.string,
  email: PropTypes.string,
  text: PropTypes.string,
  application: PropTypes.string,
  rating: PropTypes.number,
  userAgent: PropTypes.string,
  agentVersion: PropTypes.string,
  device: PropTypes.string,
  originalFeedbackId: PropTypes.string,
  handleDrawer: PropTypes.func,
  id: PropTypes.string,
  tags: PropTypes.array,
};

export default FeedbackCard;
