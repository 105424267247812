import { Tooltip as MuiTooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const Tooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#e8eaf6',
    borderRadius: '2px',
    padding: '12px',
    color: '#000',
    fontSize: '14px',
    border: '1px solid #9a9fc9',
    marginTop: '5px',
  },
}))(MuiTooltip);

export default Tooltip;
