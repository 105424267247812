import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from '@material-ui/core';
import {
  InfoOutlined as InfoIcon,
  Edit as PenIcon,
  Archive as ArchiveIcon,
  Unarchive as ActivateIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import { Tooltip } from '@tkww/shared';
import { Tooltip as MuiTooltip } from '@material-ui/core';
import _ from 'lodash';
import style from './style.scss';

export const DataGridRow = ({
  isMainCategory,
  category,
  onClickActionIcon,
}) => {
  const [showActionIcons, setShowActionIcons] = useState(false);

  return (
    <TableRow
      className={style['table-row']}
      key={category.id}
      onMouseLeave={() => !category.disabled && setShowActionIcons(false)}
      onMouseEnter={() => !category.disabled && setShowActionIcons(true)}
    >
      <TableCell>
        <div className={style['table-cell-category-name']}>
          {!isMainCategory && <hr />}
          {
            <span
              className={
                category.disabled ? style['table-cell-disabled-name'] : ''
              }
            >
              {category.name}
            </span>
          }
          {!_.isEmpty((category.description || '').trim()) && (
            <Tooltip title={category.description} placement="bottom-start">
              <InfoIcon className={style['info-icon']} />
            </Tooltip>
          )}
        </div>
      </TableCell>
      <TableCell align="center">{category.feedbackAmount}</TableCell>
      <TableCell align="center" className={style['table-cell-action']}>
        {showActionIcons && (
          <>
            <MuiTooltip title="Edit">
              <PenIcon
                color="primary"
                className="action"
                style={{ cursor: 'pointer', marginRight: '24px' }}
                onClick={() => onClickActionIcon('edit')}
              />
            </MuiTooltip>
            {category.status === 'active' ? (
              <MuiTooltip title="Archive">
                <ArchiveIcon
                  color="primary"
                  className="action"
                  style={{ cursor: 'pointer', marginRight: '24px' }}
                  onClick={() => onClickActionIcon('archive')}
                />
              </MuiTooltip>
            ) : (
              <MuiTooltip title="Unarchive">
                <ActivateIcon
                  color="primary"
                  className="action"
                  style={{ cursor: 'pointer', marginRight: '24px' }}
                  onClick={() => onClickActionIcon('activate')}
                />
              </MuiTooltip>
            )}
            {category.feedbackAmount === 0 && (
              <MuiTooltip title="Delete">
                <DeleteIcon
                  color="primary"
                  className="action"
                  style={{ cursor: 'pointer', marginRight: '24px' }}
                  onClick={() => onClickActionIcon('delete')}
                />
              </MuiTooltip>
            )}
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

export const DataGrid = ({ categories, onClickAction }) => {
  return (
    <div className={style.container}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead className={style['table-head']}>
            <TableRow>
              <TableCell className={style['table-cell-category-name']}>
                Category Name
              </TableCell>
              <TableCell className={style['table-cell']} align="center">
                Feedback Amount
              </TableCell>
              <TableCell align="left" />
            </TableRow>
          </TableHead>
          <TableBody className={style['table-body']}>
            {!categories ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <CircularProgress size="24px" />
                </TableCell>
              </TableRow>
            ) : (
              categories.map((category) => (
                <Fragment key={category.id}>
                  <DataGridRow
                    isMainCategory
                    category={category}
                    onClickActionIcon={(actionType) =>
                      onClickAction(actionType, category)
                    }
                  />
                  {category.children.length > 0 &&
                    category.children.map((subCategory) => (
                      <DataGridRow
                        key={subCategory.id}
                        isMainCategory={false}
                        category={subCategory}
                        onClickActionIcon={(actionType) =>
                          onClickAction(actionType, subCategory)
                        }
                      />
                    ))}
                </Fragment>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const categoryPropTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  children: PropTypes.array,
  description: PropTypes.string,
  feedbackAmount: PropTypes.number,
};

DataGrid.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape(categoryPropTypes)),
  onClickAction: PropTypes.func.isRequired,
};

DataGridRow.propTypes = {
  isMainCategory: PropTypes.bool.isRequired,
  category: PropTypes.shape(categoryPropTypes),
};
