import React, { useState, useEffect } from 'react';
import Joi from 'joi';
import BaseModal from '@tkww/shared/components/BaseModal';
import ConfirmationModal from '@tkww/shared/components/ConfirmationModal';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Settings } from '@tkww/client';
import { useSelector } from 'react-redux';
import { useFetch } from '@tkww/shared';
import isEmpty from 'lodash/isEmpty';
import style from './style.scss';

const inputTextSchema = Joi.object({
  name: Joi.string().max(150).required().messages({
    'string.max': 'Max 150 characters.',
  }),
  description: Joi.string().max(300).optional().allow(''),
});

const ToolsEditModal = ({ name, description, toolId, onClose, getTools }) => {
  const [textName, setTextName] = useState(name);
  const [textDesc, setTextDesc] = useState(description);
  const [isDisabled, setIsDisabled] = useState(true);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [handlePostTools, { data: resPostData }] = useFetch();
  const [handlePutTools, { data: resPutData }] = useFetch();
  const [validateMessage, setValidateMessage] = useState({
    name: '',
    description: '',
  });

  const setting = new Settings();
  const { brand } = useSelector((state) => ({
    brand: state.auth.brand,
  }));

  useEffect(() => {
    if (textName === '') {
      setValidateMessage({ ...validateMessage, name: '' });
    }
    if (textDesc === '') {
      setValidateMessage({ ...validateMessage, description: '' });
    }
  }, [textName, textDesc]);

  const handleSaveButtonStatus = (inputName, inputDescription) => {
    if (!toolId) {
      inputName !== name && !isEmpty(inputName)
        ? setIsDisabled(false)
        : setIsDisabled(true);
    } else {
      (inputName !== name || inputDescription !== description) &&
      !isEmpty(inputName)
        ? setIsDisabled(false)
        : setIsDisabled(true);
    }
  };

  const validateInputValue = () => {
    const validateResult = inputTextSchema.validate({
      name: textName,
      description: textDesc,
    });
    if (validateResult.error) {
      const errorTextMap = {};
      validateResult.error.details.forEach((item) => {
        errorTextMap[item.context.key] = item.message;
      });
      setValidateMessage(errorTextMap);
      return false;
    }
    setValidateMessage({ name: '', description: '' });
    return true;
  };

  const handleChangeName = (event) => {
    const inputName = event.target.value;
    setTextName(inputName);
    handleSaveButtonStatus(inputName, textDesc);
  };

  const handleChangeDescription = (event) => {
    const inputDescription = event.target.value;
    setTextDesc(inputDescription);
    handleSaveButtonStatus(textName, inputDescription);
  };

  useEffect(() => {
    if (resPutData || resPostData) {
      getTools();
      onClose();
    }
  }, [resPutData, resPostData]);

  const handleSave = () => {
    const isValidate = validateInputValue();
    if (isValidate) {
      if (!toolId) {
        handlePostTools(
          setting.postTools({
            name: textName,
            description: textDesc,
            brandId: brand.id,
          }),
          { failure: {} }
        );
      } else {
        setOpenConfirmationModal(true);
      }
    }
  };

  const handleConfirmation = ({ label }) => {
    if (label === 'yes') {
      handlePutTools(
        setting.putTools({
          params: { id: toolId },
          body: {
            name: textName,
            description: textDesc,
          },
        }),
        { failure: {} }
      );
    } else {
      setOpenConfirmationModal(false);
    }
  };

  return (
    <div>
      <BaseModal open={true} onClose={onClose}>
        <div className={style['modal-main']}>
          <TextField
            id="outlined-required"
            label="Tool Name"
            defaultValue={textName}
            onChange={(event) => {
              handleChangeName(event);
            }}
            variant="outlined"
            error={!isEmpty(validateMessage.name)}
            helperText={validateMessage.name}
          />
          <TextField
            id="outlined-required"
            label="Description"
            multiline
            rows={6}
            defaultValue={textDesc}
            variant="outlined"
            onChange={(event) => {
              handleChangeDescription(event);
            }}
            error={!isEmpty(validateMessage.description)}
            helperText={validateMessage.description}
          />
          <div className={style['button-container']}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={isDisabled}
            >
              {!toolId ? 'SAVE' : 'UPDATE'}
            </Button>
          </div>
        </div>
      </BaseModal>
      {openConfirmationModal && (
        <ConfirmationModal
          content={`Are you sure you want to save the tool?`}
          actions={[
            { label: 'no', variant: 'outlined' },
            { label: 'yes', variant: 'contained' },
          ]}
          onClickAction={handleConfirmation}
          onClose={() => setOpenConfirmationModal(false)}
        />
      )}
    </div>
  );
};

export default ToolsEditModal;
