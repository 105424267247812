import React from 'react';
import Brands from '../Brands';
import style from './style.scss';

export const SuperAdmin = () => {
  return (
    <div className={style.container}>
      <Brands />
    </div>
  );
};
