import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import BaseModal from '../BaseModal';
import style from './style.scss';

const ConfirmationModal = ({
  title,
  content,
  actions,
  onClickAction,
  onClose,
}) => {
  return (
    <BaseModal open={true} onClose={onClose}>
      <div className={style.container}>
        {title && <div className={style.title}>{title}</div>}
        <div className={style.content}>{content}</div>
        <div className={style.action}>
          {actions.map((action, index) => (
            <Button
              key={index}
              variant={action.variant}
              color={action.color || 'primary'}
              onClick={() => onClickAction(action)}
            >
              {action.label}
            </Button>
          ))}
        </div>
      </div>
    </BaseModal>
  );
};

ConfirmationModal.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      variant: PropTypes.string,
      color: PropTypes.string,
    })
  ),
  onClickAction: PropTypes.func,
  onClose: PropTypes.func,
};

export default ConfirmationModal;
