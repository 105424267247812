import React from 'react';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from '@material-ui/core';
import styles from './styles.scss';

const TextInput = (props) => {
  const {
    name,
    label,
    placeholder,
    value,
    setValue,
    disabled,
    error,
    onChange,
    adornment = {},
    inputProps,
    fieldProps,
    focused = false,
    isError,
    errorMessage,
  } = props;

  const handleChange = (event) => {
    if (setValue) setValue(event.target.value);
    onChange && onChange(event);
  };

  return (
    <FormControl
      variant="outlined"
      className={styles['text-input-container']}
      focused={focused}
      error={isError}
    >
      <InputLabel className={styles['input-label']}>
        {label || placeholder}
      </InputLabel>
      <OutlinedInput
        className={styles['input-outlined']}
        value={value}
        name={name}
        onChange={handleChange}
        label={label || placeholder}
        placeholder={placeholder || label}
        disabled={disabled}
        error={error}
        endAdornment={adornment.end}
        startAdornment={adornment.start}
        inputProps={{ ...inputProps, className: styles['input'] }}
        {...fieldProps}
      ></OutlinedInput>
      {isError && errorMessage && (
        <FormHelperText>{errorMessage}</FormHelperText>
      )}
    </FormControl>
  );
};

export default TextInput;
