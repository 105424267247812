import React from 'react';
import PropTypes from 'prop-types';
import { TextField as MaterialTextField } from '@material-ui/core';

import styles from './style.scss';

export const TextField = (props) => {
  const {
    label,
    placeholder,
    value,
    onChange,
    disabled = false,
    errorText = '',
  } = props;

  return (
    <MaterialTextField
      className={styles['text-field']}
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
      error={!!errorText}
      helperText={errorText}
      variant="outlined"
    />
  );
};

TextField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
};
