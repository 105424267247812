import { SECURITY_SERVICE, QA_SECURITY_SERVICE } from '@tkww/client';

const covertToCategoryMap = (categories) => {
  const mainCategoryMap = {};
  categories.forEach((category) => {
    if (category.brandId) {
      mainCategoryMap[`${category.brandId}_${category.name}`] = category.id;
    }
  });
  return mainCategoryMap;
};

export const extendMainCategories = (
  feedbackStatistics,
  categories,
  brandId
) => {
  const categoryMap = covertToCategoryMap(categories);
  feedbackStatistics.forEach((feedbackStatistics) => {
    feedbackStatistics.mainCategoryId =
      categoryMap[`${brandId}_${feedbackStatistics.mainCategoryName}`];
  });
  return feedbackStatistics;
};

export const getExportReportUrl = (
  token,
  projectIds,
  brand,
  platforms,
  startDate,
  endDate
) => {
  const HOST =
    process.env.REACT_APP_BUILD_ENV === 'production'
      ? SECURITY_SERVICE
      : QA_SECURITY_SERVICE;
  let url = `${HOST}/reports/feedbacks?token=${token}&filename=${
    brand.name
  }-${platforms.join(
    '-'
  )}-${startDate}-${endDate}&startDate=${startDate}&endDate=${endDate}`;
  projectIds.forEach((id) => (url = `${url}&projectIds=${id}`));
  return url;
};
