import { Client } from '../client';

export class Auth extends Client {
  async login(payload) {
    return fetch(`${this.host}/login`, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
  }

  async forgotPassword(payload) {
    return fetch(`${this.host}/users/forgot-password`, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
  }

  async resetPassword(payload) {
    return fetch(`${this.host}/users/reset-password`, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
  }

  async currentUser() {
    return fetch(`${this.host}/users/me`, {
      method: 'GET',
      headers: this.headers,
    });
  }

  async changeBrand(id) {
    return fetch(`${this.host}/users/change-brand`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({
        brandId: id,
      }),
    });
  }

  async inviteUser(payload) {
    return fetch(`${this.host}/users/invite`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: this.headers,
    });
  }

  async signup(payload) {
    return fetch(`${this.host}/users/signup`, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
  }

  async removeBrandUser(brandId, userId) {
    return fetch(`${this.host}/brands/${brandId}/users/${userId}`, {
      method: 'DELETE',
      headers: this.headers,
    });
  }

  async logout() {
    return fetch(`${this.host}/logout`, {
      method: 'GET',
      headers: this.headers,
    });
  }
}
