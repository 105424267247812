/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, TextField, CircularProgress } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { useFetch } from '@tkww/shared';
import { Auth, User } from '@tkww/client';
import { SHOW_MESSAGE, ERROR_MESSAGE } from '@tkww/shared/constants';
import style from './style.scss';

const fetchMessages = {
  success: {
    title: 'New password has been set.',
  },
};

export const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [fetchUserByToken, { data: userInfo, error }] = useFetch();
  const [resetPassword, { isLoading, data }] = useFetch();
  const [isValidToken, setIsValidToken] = useState(true);
  const authClient = new Auth();
  const userClient = new User();

  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const passwordToken = searchParams.get('passwordToken');

  useEffect(() => {
    if (!userInfo && !error) {
      fetchUserByToken(userClient.getUserByToken({ passwordToken }));
    } else {
      setIsValidToken(error === null);
    }
  }, [userInfo, error]);

  const resetPasswordEvent = () => {
    if (password !== confirmPassword) {
      dispatch({
        data: {
          title: 'Passwords do not match.',
          level: ERROR_MESSAGE,
        },
        type: SHOW_MESSAGE,
      });
    } else if (password.length < 6) {
      dispatch({
        data: {
          title: 'Passwords has to be at least 6 character.',
          level: ERROR_MESSAGE,
        },
        type: SHOW_MESSAGE,
      });
    } else {
      resetPassword(
        authClient.resetPassword({ passwordToken, newPassword: password }),
        fetchMessages
      );
    }
  };

  if (!isLoading && data) {
    setTimeout(() => {
      history.push('/login');
    }, 3000);
  }

  return (
    <div className={style.container}>
      <div className={style.form}>
        <div className={style.title}>AppInsights</div>
        <div className={style['welcome-message']}>
          {!isValidToken ? (
            <div style={{ color: 'red' }}>
              You can not reset your password because your token is invalid or
              expired.
            </div>
          ) : (
            `Hello, ${userInfo ? `${userInfo.email}!` : ''}`
          )}
        </div>
        {isValidToken && (
          <div className={style.hint}>Let's set your new password now!</div>
        )}
        <TextField
          disabled={!isValidToken}
          className={style.password}
          value={password}
          label="New Password(min 6 characters)"
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          size="small"
          variant="outlined"
          fullWidth
          autoFocus
        />
        <TextField
          disabled={!isValidToken}
          className={style['confirm-password']}
          value={confirmPassword}
          label="Confirm New Password"
          onChange={(e) => setConfirmPassword(e.target.value)}
          type="password"
          size="small"
          variant="outlined"
          fullWidth
        />
        <Button
          className={style.submit}
          color="primary"
          disabled={!isValidToken || isLoading}
          fullWidth
          onClick={resetPasswordEvent}
          type="submit"
          variant="contained"
        >
          {isLoading ? <CircularProgress size="24px" /> : 'RESET MY PASSWORD'}
        </Button>
      </div>
    </div>
  );
};
