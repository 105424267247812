import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import MaterialAutocomplete from '@material-ui/lab/Autocomplete';

export const Autocomplete = (props) => {
  const {
    label,
    searchKeyword,
    handleChangeKeyword,
    options,
    optionsMapping,
    errorText,
    setErrorText,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleInputChange = (value, reason) => {
    handleChangeKeyword(value);
    let open = value.length >= 3;
    if (reason === 'clear' || reason === 'reset' || value.length == 0) {
      open = false;
      setErrorText('');
    }
    setIsOpen(open);
  };

  return (
    <MaterialAutocomplete
      id="autocomplete"
      freeSolo={true}
      clearOnBlur={false}
      open={errorText ? false : isOpen}
      onOpen={() => searchKeyword.length >= 3 && setIsOpen(true)}
      inputValue={searchKeyword}
      onInputChange={(_event, value, reason) =>
        handleInputChange(value, reason)
      }
      options={optionsMapping ? optionsMapping(options) : options}
      getOptionLabel={(option) => option.value}
      renderOption={(option) => option.label}
      getOptionSelected={(option, value) => option.value === value.value}
      onChange={(_event, _value, _reason) => {
        setIsOpen(false);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          error={!!errorText}
          helperText={errorText}
        />
      )}
    />
  );
};

Autocomplete.propTypes = {
  label: PropTypes.string.isRequired,
  searchKeyword: PropTypes.string.isRequired,
  handleChangeKeyword: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  optionsMapping: PropTypes.func,
  errorText: PropTypes.string,
};
