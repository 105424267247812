import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Filter } from '@tkww/client';
import { useFetch } from '@tkww/shared';
import BaseModal from '@tkww/shared/components/BaseModal';
import TextInput from '@tkww/shared/components/TextInput';

import { filterDataFormat } from './actions';
import styles from './styles.scss';

export const SaveFilter = ({
  create = false,
  formRef = { current: {} },
  id: filterId,
  name = '',
}) => {
  const filterClient = new Filter();
  const [
    saveFetch,
    { isLoading: saveLoading, data: filterRes, error: errorRes },
  ] = useFetch();
  const [
    deleteFetch,
    { isLoading: deleteLoading, data: deleteFilter },
  ] = useFetch();
  const [openSaveModel, openSaveModelSet] = useState(false);
  const [title, titleSet] = useState(create ? '' : name);
  const [message, messageSet] = useState('');

  const onSaveHandle = async () => {
    const fetchBody = {
      name: title,
      options: filterDataFormat(formRef.current),
    };

    saveFetch(
      create
        ? filterClient.createFilter(fetchBody)
        : filterClient.updateFilter(filterId, fetchBody),
      {
        success: {
          title: `${create ? 'Saved' : 'Updated'} successfully.`,
        },
        openLoading: true,
      }
    );
  };

  useEffect(() => {
    if (errorRes) {
      console.log('errorRes:', errorRes);
      messageSet(errorRes.message);
    }
  }, [errorRes]);

  useEffect(() => {
    if (title === '') {
      messageSet('');
    }
  }, [title]);

  useEffect(() => {
    if (saveLoading && filterRes && !errorRes) {
      setTimeout(() => {
        if (create) {
          location.href = `/feedback/${filterRes.id}`;
        } else {
          location.reload();
        }
      }, 1000);
    }
  }, [create, saveLoading, filterRes, errorRes]);

  useEffect(() => {
    if (deleteLoading && deleteFilter) {
      setTimeout(() => {
        location.href = '/feedback/new-filter';
      }, 1000);
    }
  }, [deleteLoading, deleteFilter]);

  const onClickHandle = (event) => event.stopPropagation();
  const onCloseBaseModalHandle = () => openSaveModelSet(false);
  const onDeleteHandle = async () => {
    deleteFetch(filterClient.removeFilter(filterId), {
      success: { title: 'Delete Filter Successfully!' },
      failure: {},
      openLoading: true,
    });
  };

  return (
    <div className={styles['save-filter-container']} onClick={onClickHandle}>
      <BaseModal open={Boolean(openSaveModel)} onClose={onCloseBaseModalHandle}>
        <div className={styles['save-filter-model-title']}>
          {create ? 'Save as:' : 'Edit filter:'}
        </div>
        <TextInput
          label="Filter Title"
          value={title}
          setValue={titleSet}
          isError={!!message}
          errorMessage={message}
        />
        <div className={styles['save-filter-btn-group']}>
          {!create && (
            <Button color="secondary" type="button" onClick={onDeleteHandle}>
              {deleteLoading && (
                <CircularProgress size="24px" color="secondary" />
              )}
              DELETE THIS FILTER
            </Button>
          )}
          <Button
            color="primary"
            disabled={!title.trim()}
            onClick={onSaveHandle}
            type="button"
            variant="contained"
          >
            {saveLoading ? (
              <CircularProgress color="inherit" size="24px" />
            ) : (
              'Save'
            )}
          </Button>
        </div>
      </BaseModal>
      <Button
        color="primary"
        onClick={openSaveModelSet}
        type="button"
        variant="contained"
      >
        Save
      </Button>
    </div>
  );
};

export default SaveFilter;
