import { Client } from '../client';
import { stringify } from 'query-string';

export class Feedback extends Client {
  async getStatistics(projectIds, groupBy, filterBy) {
    const requestInit = { method: 'GET', headers: this.headers };
    return fetch(
      `${this.host}/feedbacks/statistics?${stringify({
        projectIds,
        groupBy,
        filterBy,
      })}`,
      requestInit
    );
  }

  async getFeedbackById(id) {
    const requestInit = { method: 'GET', headers: this.headers };
    return fetch(`${this.host}/feedbacks/${id}`, requestInit);
  }

  async getFeedbacks(
    { limit = 30, page = 1, data = {} },
    isRemoveFalse = true
  ) {
    const requestInit = { method: 'GET', headers: this.headers };
    const newData = this.filterRemoveEmpty(data, isRemoveFalse);

    return fetch(
      `${this.host}/feedbacks?limit=${limit}&page=${page}${
        stringify(newData) ? `&${stringify(newData)}` : ''
      }`,
      requestInit
    );
  }

  async createFeedbacksTags(id, body) {
    return await fetch(`${this.host}/feedbacks/${id}/tags`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify(body),
    });
  }

  async deleteFeedbacksTags(feedbackId, tagId) {
    return await fetch(`${this.host}/feedbacks/${feedbackId}/tags/${tagId}`, {
      method: 'DELETE',
      headers: this.headers,
    });
  }

  async putFeedbacksTags(feedbackId, tagId, body) {
    return await fetch(`${this.host}/feedbacks/${feedbackId}/tags/${tagId}`, {
      method: 'PUT',
      headers: this.headers,
      body: JSON.stringify(body),
    });
  }

  async postFeedbacksFavorite(feedbackId) {
    return await fetch(`${this.host}/feedbacks/${feedbackId}/favorite`, {
      method: 'POST',
      headers: this.headers,
    });
  }

  async postFeedbacksUnfavorite(feedbackId) {
    return await fetch(`${this.host}/feedbacks/${feedbackId}/unfavorite`, {
      method: 'POST',
      headers: this.headers,
    });
  }
}
