import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Auth } from '@tkww/client';
import { makeStyles } from '@material-ui/core/styles';
import style from './style.scss';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';

function getModalStyle() {
  return {
    top: `${50}%`,
    left: `${50}%`,
    transform: `translate(-${50}%, -${50}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: '28px 30px',
    textAlign: 'right',
  },
  button: {
    padding: '5px 50px',
    marginLeft: '15px',
  },
}));

export const DeleteModal = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [modalStyle] = useState(getModalStyle);
  const {
    auth: { brand },
  } = useSelector((state) => state);
  const authClient = new Auth();

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleRemoveUser = async () => {
    await authClient.removeBrandUser(brand.id, props.userId);

    window.location.reload(false);
    handleCloseModal();
  };

  return (
    <>
      <Tooltip title="Delete">
        <DeleteIcon
          color="primary"
          className={props.className}
          onClick={handleOpenModal}
        />
      </Tooltip>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <div className={style['modal-header']}>
            <IconButton aria-label="close">
              <CloseIcon onClick={handleCloseModal} />
            </IconButton>
          </div>
          <p className={style['modal-description']}>
            Are you sure to delete the selected user from this brand?
          </p>
          <Button
            variant="contained"
            size="medium"
            className={classes.button}
            onClick={handleCloseModal}
            color="inherit"
          >
            NO
          </Button>
          <Button
            variant="contained"
            className={classes.button}
            color="primary"
            size="medium"
            onClick={handleRemoveUser}
          >
            YES
          </Button>
        </div>
      </Modal>
    </>
  );
};

DeleteModal.propTypes = {
  userId: PropTypes.string,
  className: PropTypes.string,
};
