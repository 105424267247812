/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { ArrowBackIos as ArrowBackIcon } from '@material-ui/icons';
import { Feedback, Settings } from '@tkww/client';
import { useFetch } from '@tkww/shared';
import { covertToUICategories, getMessageOption } from '../../utils';
import CategoryEditModal from './CategoryEditModal';
import { DataGrid } from './DataGrid';
import ConfirmationModal from '@tkww/shared/components/ConfirmationModal';
import style from './style.scss';

const Categories = () => {
  const settings = new Settings();
  const feedback = new Feedback();
  const {
    project: { projects },
    auth: { brand },
  } = useSelector((state) => state);
  const [fetchCategories, { data: categories }] = useFetch();
  const [fetchFeedbackStatistics, { data: feedbackStatistics }] = useFetch();
  const [updateCategory] = useFetch();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [uiCategories, setUICategories] = useState(null);
  const [categoriesStatus, setCategoriesStatus] = useState('activated');
  const editCategoryRef = useRef(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const initCategoryAndFeedback = () => {
    if (brand) {
      fetchCategories(settings.getCategories({ brandId: brand.id }));
      fetchFeedbackStatistics(
        feedback.getStatistics(
          projects.map((project) => project.id),
          'subCategoryName'
        )
      );
    }
  };

  useEffect(() => {
    if (categories && feedbackStatistics) {
      setUICategories(covertToUICategories(categories, feedbackStatistics));
    }
  }, [categories, projects, feedbackStatistics]);

  useEffect(() => {
    initCategoryAndFeedback();
  }, [brand]);

  const getUICategories = () => {
    if (!uiCategories) {
      return { total: 0, categories: null };
    }
    return categoriesStatus === 'archived'
      ? uiCategories.archivedCategories
      : uiCategories.activatedCategories;
  };

  const handleOpenModal = () => {
    editCategoryRef.current = null;
    setOpenEditModal(true);
  };

  const handleClickAction = async (actionType, targetCategory) => {
    switch (actionType) {
      case 'edit':
        editCategoryRef.current = targetCategory;
        setOpenEditModal(true);
        break;
      case 'archive':
        await updateCategory(
          settings.archiveCategory(targetCategory.id),
          getMessageOption('archive', 'category')
        );
        initCategoryAndFeedback();
        break;
      case 'delete':
        editCategoryRef.current = targetCategory;
        setOpenConfirmationModal(true);
        break;
      case 'activate':
        await updateCategory(
          settings.activateCategory(targetCategory.id),
          getMessageOption('activate', 'category')
        );
        initCategoryAndFeedback();
        break;
    }
  };

  const handleClickConfirmationModal = async ({ label }) => {
    if (label === 'delete') {
      await updateCategory(
        settings.deleteCategory(editCategoryRef.current.id),
        getMessageOption('delete', 'category')
      );
      initCategoryAndFeedback();
    }
    setOpenConfirmationModal(false);
  };

  return (
    <>
      <div className={style.container}>
        {categoriesStatus === 'archived' ? (
          <div className={style['header-archived-categories']}>
            <Button
              size="small"
              color="primary"
              onClick={() => setCategoriesStatus('activated')}
              startIcon={<ArrowBackIcon />}
            >
              back
            </Button>
            <div className={style['header-title']}>Archived Categories</div>
            <div>
              {`${getUICategories().total} `}archived category(s) in this brand
            </div>
          </div>
        ) : (
          <div className={style['header-activate-categories']}>
            <div className={style['header-hint']}>
              <span>
                {`${getUICategories().total} `}
                category(s) in this brand
              </span>
              <Button
                color="primary"
                onClick={() => setCategoriesStatus('archived')}
              >
                ARCHIVED ITEMS
              </Button>
            </div>
            <Button
              variant="contained"
              color="primary"
              className={style['action-button']}
              onClick={() => handleOpenModal()}
            >
              ADD CATEGORY
            </Button>
          </div>
        )}
        <DataGrid
          categories={getUICategories().categories}
          onClickAction={handleClickAction}
        />
      </div>
      {openEditModal && (
        <CategoryEditModal
          editCategory={editCategoryRef.current}
          categories={getUICategories().categories}
          brandId={brand.id}
          onClose={() => setOpenEditModal(false)}
          onChange={() => initCategoryAndFeedback()}
        />
      )}
      {openConfirmationModal && (
        <ConfirmationModal
          title="Confirm Delete"
          content={`Are you sure you want to delete the category "${editCategoryRef.current.name}"?`}
          actions={[
            { label: 'cancel', variant: 'outlined' },
            { label: 'delete', variant: 'contained' },
          ]}
          onClickAction={handleClickConfirmationModal}
          onClose={() => setOpenConfirmationModal(false)}
        />
      )}
    </>
  );
};

export default Categories;
