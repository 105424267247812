import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { EditUserModal } from './EditUserModal';
import { TableCell, TableRow, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import style from './style.scss';
import { MoreOptionsMenu } from './MoreOptions';

const rolesMapping = {
  Member: 'User',
  Administrator: 'Admin',
};

export const BrandUserItem = (props) => {
  const { brandUser, brandId, currentUserId } = props;
  const [editorModalDisplay, setEditorModalDisplay] = useState(false);
  const { id, email, firstName, lastName, role, hasSignup } = brandUser;

  return (
    <>
      <TableRow>
        <TableCell></TableCell>
        <TableCell>
          {`${firstName ? firstName.trim() : ''} ${
            lastName ? lastName.trim() : ''
          }`}
        </TableCell>
        <TableCell>{email}</TableCell>
        <TableCell>
          {hasSignup ? '' : 'Pending '}
          {rolesMapping[role] || ''}
        </TableCell>
        {currentUserId !== id && (
          <TableCell>
            <Tooltip title="Edit">
              <EditIcon
                color="primary"
                className={style['data-action']}
                onClick={() => setEditorModalDisplay(true)}
              />
            </Tooltip>
            <MoreOptionsMenu
              userId={id}
              email={email}
              role={role}
              hasSignup={hasSignup}
              classes={style['data-action']}
            />
          </TableCell>
        )}
        {currentUserId === id && <TableCell />}
      </TableRow>
      {editorModalDisplay && (
        <EditUserModal
          setModalDisplay={setEditorModalDisplay}
          brandId={brandId}
          userId={id}
          role={role}
          firstName={firstName}
          lastName={lastName}
        />
      )}
    </>
  );
};

BrandUserItem.propTypes = {
  brandUser: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    role: PropTypes.string,
    hasSignup: PropTypes.bool,
  }),
  brandId: PropTypes.string,
  currentUserId: PropTypes.string,
};
