import React from 'react';
import isEmpty from 'lodash/isEmpty';
import Card from '../FeedbackCard';

const FeedbackList = ({
  handleDrawer,
  list,
  isOpenPanel,
  watchedList,
  query,
}) => {
  return (
    <div>
      {list.map((content) => (
        <Card
          key={content.id}
          id={content.id}
          handleDrawer={handleDrawer}
          authorName={content.author.name}
          createTime={content.timestamp.createdAt}
          email={content.author.email}
          text={content.text}
          application={content.source.application}
          rating={
            content.source.application.toLowerCase() === 'zendesk'
              ? 0
              : parseInt(content.rating, 10)
          }
          userAgent={content.osVersion || content.androidVersion}
          agentVersion={content.appVersion}
          device={content.deviceModel}
          originalFeedbackId={content.originalFeedbackId}
          tags={isEmpty(content.tags) ? [] : content.tags}
          isOpenPanel={isOpenPanel}
          isFavorite={
            query.watched ||
            watchedList.findIndex((watched) => watched.id === content.id) !== -1
          }
        />
      ))}
    </div>
  );
};

export default FeedbackList;
