import React, { useCallback } from 'react';
import copy from 'copy-to-clipboard';
import style from './style.scss';

const Copy = ({ copyContent, text }) => {
  const onCopy = useCallback(
    (e) => {
      e.stopPropagation();
      copy(copyContent);
    },
    [copyContent]
  );
  return (
    <span className={style.copy} onClick={onCopy}>
      {text}
    </span>
  );
};

export default Copy;
