import React, { useContext, useCallback, useEffect } from 'react';
import { useFetch } from '@tkww/shared';
import { Feedback as FeedbackApi } from '@tkww/client';
import PanelForm from '../PanelForm';
import { FeedbackContext } from '../../context';

const PanelCreateForm = ({ feedbackId, tagId, tempTags, setTempTags }) => {
  const {
    refreshSelectedFeedback,
    isLoadingSelected,
    setIsLoadingSelected,
  } = useContext(FeedbackContext);
  const [handleCreateTag, { data: resCreatedData }] = useFetch();
  const handleDelete = useCallback(() => {
    setTempTags(tempTags.filter((t) => t !== tagId));
  }, [tempTags, tagId]);

  useEffect(() => {
    if (resCreatedData) {
      refreshSelectedFeedback(feedbackId);
      if (!isLoadingSelected) {
        handleDelete();
      }
    }
  }, [resCreatedData, feedbackId, tempTags, tagId, isLoadingSelected]);

  const handleSave = useCallback(
    (payload) => {
      const feedbackApi = new FeedbackApi();
      setIsLoadingSelected(true);
      handleCreateTag(feedbackApi.createFeedbacksTags(feedbackId, payload), {
        failure: {},
      });
    },
    [feedbackId]
  );

  return (
    <PanelForm
      onDelete={handleDelete}
      onSave={handleSave}
      status="Unresolved"
      showDelete={tempTags.length > 1}
    />
  );
};

export default PanelCreateForm;
