import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import { Button, Paper } from '@material-ui/core';
import { Description as FileIcon } from '@material-ui/icons';
import { useFetch, DateRangePicker } from '@tkww/shared';
import { Feedback } from '@tkww/client';
import { CHANGE_DATE_RANGE } from '../../constants';
import { getExportReportUrl } from '../../utils';
import style from './style.scss';

const today = moment();
const lastMonth = moment().subtract(1, 'M');

const BasicInfo = () => {
  const feedbackClient = new Feedback();
  const dateRef = useRef({ startDate: lastMonth, endDate: today });
  const [getFeedbackTotal, { data: feedbacks }] = useFetch();
  const {
    auth: { projectIds, token, brand, platforms },
    report: {
      dateRange: { startDate, endDate },
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const handleDateRangeChange = (start, end) => {
    const startDate = moment(start).format('YYYY-MM-DD');
    const endDate = end ? moment(end).format('YYYY-MM-DD') : startDate;
    getFeedbackTotal(
      feedbackClient.getFeedbacks({
        limit: 1,
        page: 1,
        data: {
          startDate,
          endDate,
          projectIds,
        },
      })
    );
    dispatch({ type: CHANGE_DATE_RANGE, data: { startDate, endDate } });
    dateRef.current = { startDate, endDate };
  };

  const handleExportReport = () => {
    window.location.href = getExportReportUrl(
      token,
      projectIds,
      brand,
      platforms,
      startDate,
      endDate
    );
  };

  useEffect(() => {
    if (!_.isNil(projectIds)) {
      handleDateRangeChange(lastMonth, today);
    }
  }, [projectIds]);

  return (
    <Paper elevation={3}>
      <div className={style.container}>
        <div className={style.content}>
          <DateRangePicker
            defaultStartDate={lastMonth}
            defaultEndDate={today}
            onDateRangeChange={handleDateRangeChange}
          />
          <span>{_.get(feedbacks, 'pageContext.total', 0)} Received</span>
          <Link
            to={`/feedback?startDate=${dateRef.current.startDate}&endDate=${dateRef.current.endDate}`}
          >
            VIEW FEEDBACK
          </Link>
        </div>
        <Button
          color="primary"
          startIcon={<FileIcon />}
          onClick={handleExportReport}
        >
          Export
        </Button>
      </div>
    </Paper>
  );
};

export default BasicInfo;
