import { Client } from '../client';
import { stringify } from 'query-string';

export class Brand extends Client {
  async getBrands(condition) {
    const query = condition ? `?${stringify(condition)}` : '';
    return fetch(`${this.host}/brands${query}`, {
      method: 'GET',
      headers: this.headers,
    });
  }

  async createBrand(payload) {
    return fetch(`${this.host}/brands`, {
      method: 'POST',
      headers: {
        ...this.headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
  }

  async updateBrand(brandId, payload) {
    return fetch(`${this.host}/brands/${brandId}`, {
      method: 'PUT',
      headers: {
        ...this.headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
  }
}
