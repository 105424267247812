import * as constants from '../constants';
import Cookies from 'js-cookie';

const ACTION_HANDLERS = {
  [constants.LOGIN]: (state) => {
    return {
      ...state,
      isFetchingLogin: true,
      token: null,
    };
  },
  [constants.LOGIN_SUCCESS]: (state, { data }) => {
    return {
      ...state,
      isFetchingLogin: false,
      token: data.token,
      user: data.user,
    };
  },
  [constants.LOGIN_FAILURE]: (state) => {
    return {
      ...state,
      isFetchingLogin: false,
    };
  },
  [constants.LOGOUT]: (state) => {
    return {
      ...state,
      isFetchingLogout: true,
      token: null,
    };
  },
  [constants.LOGOUT_SUCCESS]: () => {
    return {};
  },
  [constants.LOGOUT_FAILURE]: (state) => {
    return {
      ...state,
      isFetchingLogout: false,
    };
  },
  [constants.GET_CURRENT_USER_SUCCESS]: (state, { data }) => {
    return {
      ...state,
      isFetchingCurrentUser: false,
      user: data,
    };
  },
  [constants.SET_BRAND]: (state, { data }) => {
    return {
      ...state,
      brand: data,
    };
  },
  [constants.SET_PLATFORM]: (state, { data }) => {
    return {
      ...state,
      platforms: data.platforms,
      projectIds: data.projectIds,
    };
  },
  [constants.REMOVE_FILTER_SUCCESS]: (state, { data }) => {
    return {
      ...state,
      user: data,
    };
  },
};

const initialState = {
  isFetchingLogin: false,
  isFetchingLogout: false,
  isFetchingCurrentUser: false,
  token: Cookies.get('authToken') || null,
  user: null,
  brand: null,
  platforms: [],
};

export const authReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
