export const defaultFilter = {
  all: {},
  untagged: { tagged: false, rating: [1, 2, 3] },
  tagged: { tagged: true },
  watched: { watched: true },
};

export const autoCompleteFields = ['label'];
export const boolFields = ['isBug', 'watched', 'hasTagNote'];
export const multipleFields = [
  'issueAppearsOn',
  'source',
  'osVersion',
  'appVersion',
  'toolId',
  'mainCategoryId',
  'subCategoryId',
  'rating',
  'bugOwner',
  'bugStatus',
];
export const stringDataKeys = ['keywords'];
