/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Joi from 'joi';
import _ from 'lodash';
import {
  TextField,
  MenuItem,
  Button,
  CircularProgress,
} from '@material-ui/core';
import BaseModal from '@tkww/shared/components/BaseModal';
import { Settings } from '@tkww/client';
import { useFetch, getObjectDiff } from '@tkww/shared';
import { getMessageOption } from '../../../utils';
import style from './style.scss';

const CATEGORY_LEVEL_MENU_ITEMS = ['Main Category', 'Sub-Category'];
const inputTextSchema = Joi.object({
  name: Joi.string().max(150).required().messages({
    'string.max': 'Max 150 characters.',
  }),
  description: Joi.string().max(500).optional().allow(''),
  parentId: Joi.string().optional().allow(''),
});
const CategoryEditModal = ({
  editCategory,
  categories,
  brandId,
  onClose,
  onChange,
}) => {
  const settings = new Settings();
  const [upsertCategory, { isLoading, error, data }] = useFetch();
  const [levelMenuItem, setLevelMenuItem] = useState(
    CATEGORY_LEVEL_MENU_ITEMS[editCategory && !editCategory.children ? 1 : 0]
  );
  const [parentId, setParentId] = useState(
    editCategory ? editCategory.parentId : ''
  );
  const [name, setName] = useState(editCategory ? editCategory.name : '');
  const [description, setDescription] = useState(
    editCategory ? editCategory.description : ''
  );
  const [validateMessage, setValidateMessage] = useState({
    name: '',
    description: '',
  });

  useEffect(() => {
    if (name === '') {
      setValidateMessage({ ...validateMessage, name: '' });
    }
    if (description === '') {
      setValidateMessage({ ...validateMessage, description: '' });
    }
  }, [name, description]);

  useEffect(() => {
    if (!isLoading && data) {
      onClose();
      onChange();
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (error) {
      setValidateMessage(
        error.message.includes('name')
          ? { name: error.message, description: '' }
          : { name: '', description: error.message }
      );
    }
  }, [error]);

  const handleChangeLevelMenuItem = (levelMenuItem) => {
    setLevelMenuItem(levelMenuItem);
    if (
      levelMenuItem === CATEGORY_LEVEL_MENU_ITEMS[1] &&
      categories.length > 0
    ) {
      setParentId(categories[0].id);
    }
  };

  const validateInput = (category) => {
    const validateResult = inputTextSchema.validate(category);
    if (validateResult.error) {
      const errorTextMap = {};
      validateResult.error.details.forEach((item) => {
        errorTextMap[item.context.key] = item.message;
      });
      setValidateMessage(errorTextMap);
      return false;
    }
    setValidateMessage({ name: '', description: '' });
    return true;
  };

  const handleClickSave = async () => {
    const payloadParentId =
      levelMenuItem === CATEGORY_LEVEL_MENU_ITEMS[0] ? '' : parentId;
    const category = { name, description, parentId: payloadParentId };
    const isValid = validateInput(category);
    if (isValid) {
      if (!editCategory) {
        await upsertCategory(
          settings.createCategory({ ...category, brandId }),
          getMessageOption('created', 'category', false)
        );
      } else {
        const updatePayload = getObjectDiff(category, editCategory, [
          'id',
          'status',
        ]);
        await upsertCategory(
          settings.updateCategory(editCategory.id, updatePayload),
          getMessageOption('updated', 'category', false)
        );
      }
    }
  };

  return (
    <BaseModal open={true} onClose={onClose}>
      <div className={style['container']}>
        <TextField
          fullWidth
          select
          label="Category Level"
          value={levelMenuItem}
          onChange={(e) => handleChangeLevelMenuItem(e.target.value)}
          variant="outlined"
        >
          {(categories.length > 0
            ? CATEGORY_LEVEL_MENU_ITEMS
            : [CATEGORY_LEVEL_MENU_ITEMS[0]]
          ).map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
        </TextField>
        {levelMenuItem === CATEGORY_LEVEL_MENU_ITEMS[1] && (
          <TextField
            fullWidth
            select
            label="Under Which Category"
            value={parentId || categories[0].id}
            onChange={(e) => setParentId(e.target.value)}
            variant="outlined"
          >
            {categories.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
          </TextField>
        )}
        <TextField
          fullWidth
          label="Category Name"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={!_.isEmpty(validateMessage.name)}
          helperText={
            !_.isEmpty(validateMessage.name) ? validateMessage.name : ''
          }
        />
        <TextField
          fullWidth
          label="Description (optional)"
          multiline
          rows={4}
          variant="outlined"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          error={!_.isEmpty(validateMessage.description)}
          helperText={validateMessage.description || ''}
        />
        <Button
          variant="contained"
          color="primary"
          className={style['action-button']}
          onClick={() => handleClickSave()}
        >
          {isLoading ? <CircularProgress size="24px" /> : 'Save'}
        </Button>
      </div>
    </BaseModal>
  );
};

CategoryEditModal.propTypes = {
  editCategory: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    children: PropTypes.array,
    description: PropTypes.string,
  }),
  categories: PropTypes.array,
  brandId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CategoryEditModal;
