import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import { Filter, Settings } from '@tkww/client';
import { useFetch } from '@tkww/shared';
import BtnCheckbox from '@tkww/shared/components/BtnCheckbox';
import { DateRangePicker } from '@tkww/shared/components/DateRangePicker';
import LinkageSelects from '@tkww/shared/components/LinkageSelects';
import Selects from '@tkww/shared/components/Selects';
import Suggestion from '@tkww/shared/components/Suggestion';
import TextInput from '@tkww/shared/components/TextInput';
import { FeedbackContext } from '../../context';
import { filterFormat } from '../Feedback/actions';
import {
  appVersionMapping,
  categoriesMapping,
  filterSubmit,
  labelsMapping,
  osVersionMapping,
  toolMapping,
} from './actions';
import styles from './styles.scss';
import moment from 'moment';

export const FilterForm = (props) => {
  const {
    create,
    filterData,
    fieldChanged = {},
    fieldChangedSet,
    formRef,
    projectIds,
    formData,
    setFormData,
  } = props;
  const history = useHistory();
  const [osVersionOptions, setOsVersionOptions] = useState([]);
  const [fetchOsVersionData, { data: osVersionData }] = useFetch();
  const [appVersionOptions, setAppVersionOptions] = useState([]);
  const [fetchAppVersionData, { data: appVersionData }] = useFetch();
  const [labelsOptions, setLabelsOptions] = useState([]);
  const [fetchLabelsData, { data: labelsData }] = useFetch();

  const filter = new Filter();

  const { categories, tools } = useContext(FeedbackContext);

  const fieldIsChanged = (key, value) => {
    let oldData, newData;
    const oldDateList = !create ? filterData.options : filterFormat();
    if (Array.isArray(oldDateList[key])) {
      oldData = oldDateList[key].map((item) => item.toString()).sort();
      newData = value.map((item) => item.toString()).sort();
    } else if (key === 'startDate' || key === 'endDate') {
      oldData = moment(oldDateList[key]).format('YYYY-MM-DD');
      newData = moment(value).format('YYYY-MM-DD');
    } else {
      oldData = oldDateList[key];
      newData = value;
    }

    return JSON.stringify(oldData) !== JSON.stringify(newData);
  };

  const Categories = LinkageSelects({
    options: categoriesMapping(categories || []),
    setValue: ([mainValue, subValue]) => {
      setFormData({
        ...formData,
        mainCategoryId: mainValue,
        subCategoryId: subValue,
      });
      fieldChangedSet &&
        fieldChangedSet({
          ...fieldChanged,
          mainCategoryId: fieldIsChanged('mainCategoryId', mainValue),
          subCategoryId: fieldIsChanged('subCategoryId', subValue),
        });
    },
    main: {
      label: 'Category',
      name: 'mainCategoryId',
      value: formData.mainCategoryId,
      focused: fieldChanged.mainCategoryId,
    },
    sub: {
      value: formData.subCategoryId,
      label: 'Sub-category',
      name: 'subCategoryId',
      focused: fieldChanged.subCategoryId,
    },
  });

  const updateFormData = (key, value) => {
    setFormData({ ...formData, [key]: value });
    fieldChangedSet &&
      fieldChangedSet({
        ...fieldChanged,
        [key]: fieldIsChanged(key, value),
      });
  };

  const clearFilter = () => {
    if (create) {
      history.push('/feedback/new-filter');
    } else {
      setFormData({ ...filterData.options });
      fieldChangedSet && fieldChangedSet({});
    }
  };

  const GridClasses = { container: styles['grid-container'] };
  const DateRangeClasses = {
    root: styles['date-range'],
    'text-field': styles['text-field'],
  };

  useEffect(() => {
    if (!osVersionData && projectIds) {
      fetchOsVersionData(filter.getOsVersionData(projectIds));
    }
    if (osVersionData) {
      setOsVersionOptions(osVersionMapping(osVersionData, projectIds));
    }
  }, [osVersionData, projectIds]);

  useEffect(() => {
    if (!appVersionData && projectIds) {
      fetchAppVersionData(filter.getAppVersionData(projectIds));
    }
    if (appVersionData) {
      setAppVersionOptions(appVersionMapping(appVersionData, projectIds));
    }
  }, [appVersionData, projectIds]);

  useEffect(() => {
    if (!labelsData) {
      fetchLabelsData(filter.getLabelsData());
    }
    if (labelsData) {
      setLabelsOptions(labelsMapping(labelsData));
    }
  }, [labelsData]);

  return (
    <Container
      maxWidth={false}
      disableGutters
      className={styles['filter-form-container']}
    >
      <form
        ref={formRef}
        action="./"
        method="get"
        encType="application/x-www-form-urlencoded"
      >
        <Grid container classes={GridClasses}>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <Selects
              focused={fieldChanged.issueAppearsOn}
              value={formData.issueAppearsOn}
              setValue={(value) => updateFormData('issueAppearsOn', value)}
              label="Issue appears on"
              name="issueAppearsOn"
              options={[
                { value: 'Android', label: 'Android' },
                { value: 'iOS', label: 'iOS' },
                { value: 'Web', label: 'Web' },
              ]}
              multiple
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <Selects
              focused={fieldChanged.source}
              value={formData.source}
              setValue={(value) => updateFormData('source', value)}
              label="Source"
              name="source"
              options={[
                { value: 'Play Store', label: 'Google Play' },
                { value: 'App Store', label: 'Apple Store' },
                { value: 'Zendesk', label: 'Zendesk' },
                { value: 'UserVoice', label: 'UserVoice' },
              ]}
              multiple
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <Selects
              focused={fieldChanged.osVersion}
              value={formData.osVersion}
              setValue={(value) => updateFormData('osVersion', value)}
              label="OS Version"
              name="osVersion"
              options={osVersionOptions}
              multiple
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <Selects
              focused={fieldChanged.appVersion}
              value={formData.appVersion}
              setValue={(value) => updateFormData('appVersion', value)}
              label="APP Version"
              name="appVersion"
              options={appVersionOptions}
              multiple
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <Selects
              focused={fieldChanged.rating}
              value={
                formData.rating
                  ? formData.rating.map((r) => r.toString())
                  : ['-1']
              }
              setValue={(value) => updateFormData('rating', value)}
              label="Rating Stars"
              name="rating"
              options={[
                { value: '1', label: '1' },
                { value: '2', label: '2' },
                { value: '3', label: '3' },
                { value: '4', label: '4' },
                { value: '5', label: '5' },
              ]}
              multiple
              disabled={
                formData.source &&
                formData.source.filter(
                  (item) => item.search(/(Play Store)|(App Store)/i) > -1
                ).length !== formData.source.length
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6} xl={3}>
            <DateRangePicker
              classes={DateRangeClasses}
              startDate={new Date(formData.startDate)}
              endDate={
                formData.endDate ? new Date(formData.endDate) : undefined
              }
              focused={fieldChanged.startDate || fieldChanged.endDate}
              name="dateRange"
              onDateRangeChange={(start, end) => {
                setFormData({
                  ...formData,
                  startDate: start,
                  endDate: end,
                });
                fieldChangedSet &&
                  fieldChangedSet({
                    ...fieldChanged,
                    startDate: fieldIsChanged('startDate', start),
                    endDate: fieldIsChanged('endDate', end),
                  });
              }}
              onCalendarClose={() => {
                if (!formData.endDate) {
                  setFormData({ ...formData, endDate: formData.startDate });
                  fieldChangedSet &&
                    fieldChangedSet({
                      ...fieldChanged,
                      endDate: fieldIsChanged('endDate', formData.startDate),
                    });
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <Selects
              focused={fieldChanged.toolId}
              value={formData.toolId}
              setValue={(value) => updateFormData('toolId', value)}
              label="Tool"
              name="toolId"
              options={toolMapping(tools)}
              multiple
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            {Categories[0]}
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            {Categories[1]}
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <BtnCheckbox
              focused={fieldChanged.isBug}
              name="isBug"
              checked={formData.isBug}
              label="Only show bugs"
              setValue={(value) => updateFormData('isBug', value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <Selects
              focused={fieldChanged.bugOwner}
              value={formData.bugOwner}
              setValue={(value) => updateFormData('bugOwner', value)}
              label="Bug Owner"
              name="bugOwner"
              options={[
                { value: 'Mobile team', label: 'Mobile team' },
                { value: 'Web team', label: 'Web team' },
              ]}
              disabled={!formData.isBug}
              multiple
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <Selects
              focused={fieldChanged.bugStatus}
              value={formData.bugStatus}
              setValue={(value) => updateFormData('bugStatus', value)}
              label="Bug Status"
              name="bugStatus"
              options={[
                { value: 'Resolved', label: 'Resolved' },
                { value: 'Unresolved', label: 'Unresolved' },
              ]}
              disabled={!formData.isBug}
              multiple
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <Suggestion
              focused={fieldChanged.label}
              options={labelsOptions}
              label="Label"
              name="label"
              multiple
              value={formData.label}
              setValue={(value) => updateFormData('label', value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <BtnCheckbox
              focused={fieldChanged.hasTagNote}
              name="hasTagNote"
              checked={formData.hasTagNote}
              label="It has a note."
              setValue={(value) => updateFormData('hasTagNote', value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <BtnCheckbox
              focused={fieldChanged.watched}
              name="watched"
              checked={formData.watched}
              label="Watched"
              setValue={(value) => updateFormData('watched', value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <TextInput
              focused={fieldChanged.keywords}
              value={formData.keywords}
              placeholder="Search keyword"
              name="keywords"
              label="Search keyword"
              setValue={(value) => updateFormData('keywords', value)}
              adornment={{
                end: (
                  <InputAdornment>
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justify="flex-end"
          className={styles['filter-container']}
        >
          <Grid item>
            <Button
              className={styles['clear-btn']}
              color="primary"
              onClick={clearFilter}
            >
              Clear
            </Button>
            <Button
              color="primary"
              variant="contained"
              className={styles['filter-btn']}
              onClick={() => filterSubmit(formData)}
            >
              Filter
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};
