import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFetch } from '@tkww/shared';
import { Feedback } from '@tkww/client';
import UnArchived from './UnArchived';
import Archived from './Archived';

const Tools = () => {
  const [isArchived, setArchived] = useState(false);
  const { projects } = useSelector((state) => state.project);
  const [fetchFeedbackStatistics, { data: toolStatistics }] = useFetch([]);

  useEffect(() => {
    const feedback = new Feedback();
    const projectIds = projects.map((project) => project.id);
    fetchFeedbackStatistics(feedback.getStatistics(projectIds, 'toolName'));
  }, [projects]);

  const switchArchived = () => {
    setArchived(!isArchived);
  };

  return (
    <div>
      {!isArchived ? (
        <UnArchived
          switchArchived={switchArchived}
          toolStatistics={toolStatistics}
        />
      ) : (
        <Archived
          switchArchived={switchArchived}
          toolStatistics={toolStatistics}
        />
      )}
    </div>
  );
};
export default Tools;
