import React, { useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import Unarchive from '@material-ui/icons/Unarchive';
import Grid from '@material-ui/core/Grid';
import Archive from '@material-ui/icons/Archive';
import { ConfirmationModal } from '@tkww/shared';
import ToolsEditModal from '../ToolsEditModal';
import { Settings } from '@tkww/client';
import Tooltip from '@material-ui/core/Tooltip';

const ButtonsWrapper = ({
  amount = 1,
  getTools,
  toolId,
  description,
  name,
  pageType,
}) => {
  const [openConfirmationModal, setConfirmationModal] = useState(false);
  const [openToolsModal, setToolsModal] = useState(false);
  const [type, setType] = useState('');
  const [content, setContent] = useState('');

  const handleAction = () => {
    if (pageType !== 'archived') {
      setType('unArchive');
      setContent('Are you sure to archive the selected tool?');
      setConfirmationModal(true);
    } else {
      setType('archive');
      setContent('Are you sure to unarchive the selected tool?');
      setConfirmationModal(true);
    }
  };
  const onDelete = () => {
    setType('delete');
    setContent('Are you sure to delete the selected tool from this brand?');
    setConfirmationModal(true);
  };
  const handleClickConfirmationModal = async (action) => {
    const setting = new Settings();
    if (action.label === 'YES') {
      if (type === 'archive') {
        await setting.activateTools(toolId);
      } else if (type === 'delete') {
        await setting.deletTools(toolId);
      } else {
        await setting.archiveTools(toolId);
      }
      getTools();
    }
    setConfirmationModal(false);
  };

  return (
    <Grid>
      <Tooltip title="Edit">
        <CreateIcon
          color="primary"
          className="action"
          style={{ cursor: 'pointer', marginRight: '24px' }}
          onClick={() => setToolsModal(true)}
        />
      </Tooltip>
      {pageType !== 'archived' ? (
        <Tooltip title="Archive">
          <Archive
            color="primary"
            className="action"
            style={{ cursor: 'pointer', marginRight: '24px' }}
            onClick={handleAction}
          />
        </Tooltip>
      ) : (
        <Tooltip title="Unarchive">
          <Unarchive
            color="primary"
            className="action"
            style={{ cursor: 'pointer', marginRight: '24px' }}
            onClick={handleAction}
          />
        </Tooltip>
      )}
      {parseInt(amount, 10) === 0 ? (
        <Tooltip title="Delete">
          <DeleteIcon
            color="primary"
            className="action"
            style={{ cursor: 'pointer', marginRight: '24px' }}
            onClick={onDelete}
          />
        </Tooltip>
      ) : (
        ''
      )}
      {openConfirmationModal && (
        <ConfirmationModal
          onClose={() => setConfirmationModal(false)}
          content={content}
          actions={[
            { label: 'NO', variant: 'outlined' },
            { label: 'YES', variant: 'contained' },
          ]}
          onClickAction={handleClickConfirmationModal}
        />
      )}
      {openToolsModal && (
        <ToolsEditModal
          onClose={() => setToolsModal(false)}
          getTools={getTools}
          name={name}
          description={description}
          toolId={toolId}
        />
      )}
    </Grid>
  );
};

export default ButtonsWrapper;
