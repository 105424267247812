import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MaterialSelect,
  FormHelperText,
} from '@material-ui/core';
import clsx from 'clsx';

import style from './style.scss';

export const Select = (props) => {
  const {
    name,
    label,
    value,
    options,
    onChange,
    isError,
    errorMessage,
    disabled,
  } = props;

  return (
    <FormControl
      variant="outlined"
      className={style['form-control']}
      error={isError}
      disabled={disabled}
    >
      <InputLabel>{label}</InputLabel>
      <MaterialSelect
        name={name}
        label={label}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={style['field-select-container']}
      >
        {options.map((item, index) => (
          <MenuItem
            key={index}
            value={item.value}
            disabled={item.isGroup || false}
            classes={{ root: item.isGroup ? style.disabled : style.enable }}
          >
            {item.label}
          </MenuItem>
        ))}
      </MaterialSelect>
      {isError && errorMessage && (
        <FormHelperText>{errorMessage}</FormHelperText>
      )}
    </FormControl>
  );
};

Select.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
};
