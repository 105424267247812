import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Selects from '@tkww/shared/components/Selects';

const SelectsFormatted = (props) => {
  const {
    disabled,
    focused,
    label,
    name,
    onChange,
    options,
    setValue,
    value,
  } = props;
  const onChangeHandle = (value) => {
    setValue && setValue(value);
    onChange && onChange(value);
  };

  return (
    <Selects
      disabled={disabled}
      focused={focused}
      label={label}
      multiple
      name={name}
      options={options}
      setValue={onChangeHandle}
      value={value}
    />
  );
};

const LinkageSelects = ({ options = [], main, sub, setValue }) => {
  const getSubOptions = (value = []) => {
    const filterOptions = options.filter(
      (option) => value.includes('-1') || value.includes(option.value)
    );
    let subOptionsArr = [];
    filterOptions.forEach((option) => {
      subOptionsArr = subOptionsArr.concat(option.children || []);
    });

    return subOptionsArr;
  };

  const onMainSelectsChange = (value) => {
    const subOptionsArr = getSubOptions(value);
    const findOptions = subOptionsArr.filter(
      (subOption) =>
        Array.isArray(sub.value) && sub.value.includes(subOption.value)
    );
    const mainValue = value;
    const subValue = !findOptions.length
      ? []
      : findOptions.map((findOption) => findOption.value);
    setValue && setValue([mainValue, subValue]);
  };

  const onSubSelectsChange = (value) => {
    setValue && setValue([main.value, value]);
  };

  const subOptions = getSubOptions(main.value);

  return [
    <SelectsFormatted
      {...main}
      options={options}
      onChange={onMainSelectsChange}
    />,
    <SelectsFormatted
      {...sub}
      disabled={subOptions.length <= 0}
      options={subOptions}
      onChange={onSubSelectsChange}
    />,
  ];
};

const optionsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    children: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    label: PropTypes.string,
    value: PropTypes.string,
  })
);

const SelectsPropTypes = PropTypes.shape({
  options: optionsPropTypes.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  setValue: PropTypes.func,
  value: PropTypes.array,
});

LinkageSelects.PropTypes = {
  options: optionsPropTypes.isRequired,
  main: SelectsPropTypes.isRequired,
  sub: SelectsPropTypes.isRequired,
};

export default LinkageSelects;
