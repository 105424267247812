import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import BaseModal from '@tkww/shared/components/BaseModal';
import { TextField, useFetch } from '@tkww/shared';
import { Button } from '@material-ui/core';
import { Brand } from '@tkww/client';
import Joi from 'joi';
import style from './style.scss';

const schema = Joi.object({
  'Brand Name': Joi.string().max(30),
});

const BrandModal = (props) => {
  const { open, setModalOpen, brand, setReload } = props;
  const [brandName, setBrandName] = useState('');
  const [errorText, setErrorText] = useState('');
  const [handleFetch, { data, error }] = useFetch();

  useEffect(() => {
    if (open) {
      setErrorText('');
    }
  }, [open]);

  useEffect(() => {
    setBrandName(brand.name || '');
  }, [brand]);

  useEffect(() => {
    if (data) {
      setModalOpen(false);
      setReload(true);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setErrorText('Brand is already taken.');
    }
  }, [error]);

  useEffect(() => {
    if (brandName === '') {
      setErrorText('');
    }
  }, [brandName]);

  const handleClickButton = () => {
    const result = schema.validate({ 'Brand Name': brandName });
    if (result.error) {
      setErrorText(result.error.message);
    } else {
      setErrorText('');
      const client = new Brand();
      if (brand.id) {
        handleFetch(client.updateBrand(brand.id, { name: brandName }));
      } else {
        handleFetch(client.createBrand({ name: brandName }));
      }
    }
  };

  return (
    <BaseModal
      open={open}
      onClose={() => setModalOpen(false)}
      title={brand.id ? 'Edit Brand' : 'Add A New Brand'}
    >
      <div className={style['input-row']}>
        <TextField
          label="Brand Name"
          placeholder="Brand Name"
          value={brandName}
          onChange={setBrandName}
          errorText={errorText}
        />
      </div>
      <div className={style['button-row']}>
        <Button variant="contained" color="primary" onClick={handleClickButton}>
          {brand.id ? 'UPDATE' : 'ADD'}
        </Button>
      </div>
    </BaseModal>
  );
};

export default BrandModal;
