/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Paper } from '@material-ui/core';
import TabBar from '@tkww/shared/components/TabBar';
import BasicInfo from '../BasicInfo';
import ReportDetail from '../ReportDetail';
import SubCategoryHeader from '../SubCategoryHeader';
import style from './style.scss';

const tabItems = [{ label: 'Main Category' }, { label: 'Tools' }];

const Report = () => {
  const [selectedMainCategory, setSelectedMainCategory] = useState(null);
  const {
    auth: { brand },
    report: { mainCategories },
  } = useSelector((state) => state);
  const [reportType, setReportType] = useState('mainCategory');
  const handleChangeTabBar = (tabIndex) => {
    setReportType(tabIndex === 0 ? 'mainCategory' : 'tool');
  };

  useEffect(() => {
    if (selectedMainCategory) {
      setSelectedMainCategory(null);
    }
  }, [brand]);

  return (
    <div className={style.container}>
      <BasicInfo />
      <Paper elevation={3} className={style['report-detail-container']}>
        {selectedMainCategory ? (
          <SubCategoryHeader
            mainCategories={mainCategories}
            selectedMainCategory={selectedMainCategory}
            onSelectMainCategory={setSelectedMainCategory}
          />
        ) : (
          <div className={style.header}>
            <span className={style.title}>View by:</span>
            <TabBar
              width="340px"
              items={tabItems}
              onChange={handleChangeTabBar}
            />
          </div>
        )}
        <ReportDetail
          reportType={selectedMainCategory ? 'subCategory' : reportType}
          selectedMainCategory={selectedMainCategory}
          onSelectMainCategory={setSelectedMainCategory}
        />
      </Paper>
    </div>
  );
};

export default Report;
