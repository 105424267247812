import Joi from 'joi';

export const schemaWithBug = Joi.object({
  issueAppearsOn: Joi.array()
    .items(Joi.string().valid('Android', 'iOS', 'Web').required())
    .required(),
  toolId: Joi.string().required(),
  categoryId: Joi.string().required(),
  notes: Joi.string().allow('').max(100),
  storyId: Joi.string().allow(''),
  owner: Joi.string().valid('Mobile team', 'Web team').required(),
  status: Joi.string().valid('Unresolved', 'Resolved').required(),
  isBug: Joi.boolean(),
});

export const schemaWithoutBug = Joi.object({
  issueAppearsOn: Joi.array()
    .items(Joi.string().valid('Android', 'iOS', 'Web').required())
    .required(),
  toolId: Joi.string().required(),
  categoryId: Joi.string().required(),
  notes: Joi.string().allow('').max(100),
  storyId: Joi.string().allow(''),
  isBug: Joi.boolean(),
});
