import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { BrandUserItem } from './BrandUserItem';
import style from './style.scss';

export const BrandUserList = (props) => {
  const { brandUsers, brandId, currentUserId } = props;
  return (
    <TableContainer component={Paper}>
      <Table className={style['table-container']}>
        <TableHead className={style['table-header']}>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Role</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {brandUsers.length > 0 &&
            brandUsers.map((brandUser, index) => (
              <BrandUserItem
                key={index}
                brandUser={brandUser}
                brandId={brandId}
                currentUserId={currentUserId}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

BrandUserList.propTypes = {
  brandUsers: PropTypes.array,
  brandId: PropTypes.string,
  currentUserId: PropTypes.string,
};
