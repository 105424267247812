import React, { useEffect, useState } from 'react';
import { useFetch } from '@tkww/shared';
import { Settings } from '@tkww/client';
import { useSelector } from 'react-redux';
import ToolsEditModal from '../ToolsEditModal';
import style from './style.scss';
import { DataGrid } from '../DataGrid';

const Archived = ({ switchArchived, toolStatistics }) => {
  const [open, setOpen] = useState(false);
  const [archivedTools, setArchivedTools] = useState([]);
  const [handleFetchTool, { data: tools }] = useFetch([]);

  const { brand } = useSelector((state) => ({
    brand: state.auth.brand,
  }));

  const getTools = () => {
    const setting = new Settings();
    handleFetchTool(
      setting.getTools({ brandId: brand.id, includeArchived: true })
    );
  };

  useEffect(() => {
    getTools();
  }, [brand]);

  useEffect(() => {
    if (tools.length) {
      setArchivedTools(tools.filter((t) => t.status === 'inactive'));
    }
  }, [tools]);

  return (
    <div>
      <div>
        <span
          className={style['back-button']}
          onClick={switchArchived}
        >{`< BACK`}</span>
        <p className={style.title}>Archived Tools</p>
        <p className={style['content-total']}>
          {archivedTools.length} archived tool(s) in this brand
        </p>
      </div>
      <div className={style['data-grid-container']}>
        <DataGrid
          list={archivedTools}
          getTools={getTools}
          toolStatistics={toolStatistics}
          pageType="archived"
        />
      </div>
      {open && (
        <ToolsEditModal
          open={open}
          onClose={() => setOpen(false)}
          getTools={getTools}
        />
      )}
    </div>
  );
};
export default Archived;
