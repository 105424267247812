export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ERROR_MESSAGE = 'ERROR_MESSAGE';
export const WARNING_MESSAGE = 'WARNING_MESSAGE';
export const SUCCESS_MESSAGE = 'SUCCESS_MESSAGE';

export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';

export const SHOW_TOOLSEDIT_MODAL = 'SHOW_TOOLSEDIT_MODAL';
export const HIDE_TOOLSEDIT_MODAL = 'HIDE_TOOLSEDIT_MODAL';
export const SET_TOOLSEDIT_MODAL_DATA = 'SET_TOOLSEDIT_MODAL_DATA';
