import { Client } from '../client';

export class Filter extends Client {
  async removeFilter(id) {
    return fetch(`${this.host}/user-filters/${id}`, {
      method: 'DELETE',
      headers: this.headers,
    });
  }

  async createFilter({ name = '', options = {} }) {
    return fetch(`${this.host}/user-filters`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify({ name, options: this.filterRemoveEmpty(options) }),
    });
  }

  async updateFilter(filterId, { name = '', options = {} }) {
    return fetch(`${this.host}/user-filters/${filterId}`, {
      method: 'PUT',
      headers: this.headers,
      body: JSON.stringify({ name, options: this.filterRemoveEmpty(options) }),
    });
  }

  async getOsVersionData() {
    return fetch(`${this.host}/os-versions`, {
      method: 'GET',
      headers: this.headers,
    });
  }

  async getAppVersionData() {
    return fetch(`${this.host}/versions`, {
      method: 'GET',
      headers: this.headers,
    });
  }

  async getLabelsData() {
    return fetch(`${this.host}/labels?includeArchived=false`, {
      method: 'GET',
      headers: this.headers,
    });
  }
}
