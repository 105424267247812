import { Client } from '../client';
import { stringify } from 'query-string';

export class User extends Client {
  async getUsers(condition) {
    const query = condition ? `?${stringify(condition)}` : '';
    return fetch(`${this.host}/users${query}`, {
      method: 'GET',
      headers: this.headers,
    });
  }

  async updateUser(userId, payload) {
    return fetch(`${this.host}/users/${userId}`, {
      method: 'PUT',
      headers: {
        ...this.headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
  }

  async inviteUser(params) {
    return fetch(`${this.host}/users/invite`, {
      method: 'POST',
      headers: {
        ...this.headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
  }

  async getBrandUsers(brandId) {
    return fetch(`${this.host}/brands/${brandId}/users`, {
      method: 'GET',
      headers: this.headers,
    });
  }

  async updateBrandUser(brandId, userId, payload) {
    return fetch(`${this.host}/brands/${brandId}/users/${userId}`, {
      method: 'PUT',
      headers: {
        ...this.headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
  }

  async getRoleFeatures() {
    return fetch(`${this.host}/role-features`, {
      method: 'GET',
      headers: this.headers,
    });
  }

  async getUserByToken(condition) {
    return fetch(`${this.host}/users/by-token?${stringify(condition)}`, {
      method: 'GET',
      headers: this.headers,
    });
  }

  async setUserAccesses(userId, payload) {
    return fetch(`${this.host}/users/${userId}/accesses`, {
      method: 'POST',
      headers: {
        ...this.headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
  }
}
