import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { SET_RELOAD_COLLABORATORS } from '../../constants';
import BaseModal from '@tkww/shared/components/BaseModal';
import { Select, Autocomplete, useFetch, sharedConstants } from '@tkww/shared';
import { Button } from '@material-ui/core';
import { User } from '@tkww/client';
import Joi from 'joi';
import style from './style.scss';

const roleOptions = [
  { label: 'Admin', value: 'Administrator' },
  { label: 'User', value: 'Member' },
];

const handleUserOptionRender = (firstName, lastName, email) => {
  return (
    <div className={style['autocomplete-option']}>
      <span>{`${firstName} ${lastName}`}</span>
      <span>{email}</span>
    </div>
  );
};

const userOptionsMapping = (data) =>
  data.map((item) => ({
    label: handleUserOptionRender(
      item.firstName || '',
      item.lastName || '',
      item.email
    ),
    value: item.email,
  }));

const schema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .error(() => new Error('Invalid email address')),
});

export const InviteUserModal = ({ setModalDisplay, brandId }) => {
  const [role, setRole] = useState('Administrator');
  const [email, setEmail] = useState('');
  const [errorText, setErrorText] = useState('');
  const [handleFetchUsers, { data: users }] = useFetch([]);
  const [handleFetchInviteUser, { data: inviteResult }] = useFetch();
  const [handleFetchUserAccesses, { data: setAccessesResult }] = useFetch();
  const client = new User();
  const dispatch = useDispatch();

  useEffect(() => {
    handleFetchUsers(client.getUsers({ notTiedToBrandId: brandId }));
  }, []);

  useEffect(() => {
    if (inviteResult || setAccessesResult) {
      setModalDisplay(false);
      dispatch({
        type: SET_RELOAD_COLLABORATORS,
        data: { reloadCollaborators: true },
      });
    }
  }, [inviteResult, setAccessesResult]);

  const handleClickInvite = () => {
    const result = schema.validate({ email });
    if (result.error) {
      setErrorText(result.error.message);
    } else {
      const fetchOptions = {
        success: {
          text: 'Invite has been sent.',
        },
        failure: {},
      };
      const selectedUser = users.find(
        (user) => user.email === email.trim().toLowerCase()
      );
      if (selectedUser) {
        handleFetchUserAccesses(
          client.setUserAccesses(selectedUser.id, { role, brandId }),
          fetchOptions
        );
      } else {
        handleFetchInviteUser(
          client.inviteUser({ email, role, brands: [brandId] }),
          fetchOptions
        );
      }
    }
  };

  return (
    <BaseModal open={true} onClose={() => setModalDisplay(false)}>
      <div className={style['input-row']}>
        <Select
          label="Role"
          value={role}
          options={roleOptions}
          onChange={setRole}
        />
      </div>
      <div className={style['input-row']}>
        <Autocomplete
          label="Email"
          searchKeyword={email}
          handleChangeKeyword={setEmail}
          options={users}
          optionsMapping={userOptionsMapping}
          errorText={errorText}
          setErrorText={setErrorText}
        />
      </div>
      <div className={style['button-row']}>
        <Button variant="contained" color="primary" onClick={handleClickInvite}>
          INVITE
        </Button>
      </div>
    </BaseModal>
  );
};
