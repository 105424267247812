/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import style from './style.scss';
import { PieChart, useFetch } from '@tkww/shared';
import {
  percentageDataTransform,
  bugOwnerDataTransform,
} from '@tkww/shared/utils';
import { extendMainCategories } from '../../utils';
import { Feedback, Settings } from '@tkww/client';
import { TableList } from '../TableList';
import { CHANGE_MAIN_CATEGORIES } from '../../constants';

const bugOwnerHeadCells = [
  { field: 'tagOwner', headerName: 'Bug Owner' },
  { field: 'bug', headerName: 'Bug' },
  { field: 'resolved', headerName: 'Resolved' },
  { field: 'unresolved', headerName: 'Unresolved' },
];

const ReportDetail = ({
  reportType,
  selectedMainCategory,
  onSelectMainCategory,
}) => {
  const headCells = [
    {
      field: `${reportType}Name`,
      headerName: _.startCase(reportType),
      sortable: true,
    },
    { field: 'total', headerName: 'Record', sortable: true },
    { field: 'percentage', headerName: 'Percentage', sortable: true },
  ];

  const feedbackClient = new Feedback();
  const settingsClient = new Settings();

  const dispatch = useDispatch();
  const {
    auth: { projectIds, brand },
    report: {
      dateRange: { startDate, endDate },
    },
  } = useSelector((state) => state);
  const [getStatistics, { data: statisticsLists }] = useFetch();
  const [getBugOwnerStatistics, { data: bugOwnerLists }] = useFetch();
  const [fetchCategories, { data: categories }] = useFetch();

  useEffect(() => {
    if (!_.isNil(projectIds) && startDate && endDate) {
      const categoryQuery =
        reportType === 'subCategory' && selectedMainCategory
          ? `mainCategoryId=${selectedMainCategory.mainCategoryId},`
          : '';
      getStatistics(
        feedbackClient.getStatistics(
          projectIds,
          `${reportType}Name`,
          `${categoryQuery}startDate=${startDate},endDate=${endDate}`
        ),
        {
          transformFun: percentageDataTransform,
        }
      );
      if (reportType !== 'subCategory') {
        getBugOwnerStatistics(
          feedbackClient.getStatistics(
            projectIds,
            `tagOwner,tagStatus`,
            `startDate=${startDate},endDate=${endDate}`
          ),
          { transformFun: bugOwnerDataTransform }
        );
      }
    }
  }, [reportType, selectedMainCategory, projectIds, startDate, endDate]);

  useEffect(() => {
    if (reportType === 'mainCategory' && !_.isEmpty(statisticsLists)) {
      if (_.isEmpty(categories)) {
        fetchCategories(settingsClient.getCategories());
      } else {
        dispatch({
          type: CHANGE_MAIN_CATEGORIES,
          data: {
            mainCategories: extendMainCategories(
              statisticsLists,
              categories,
              _.get(brand, 'id')
            ),
          },
        });
      }
    }
  }, [statisticsLists, categories]);

  const handleSelectMainCategory = useCallback((data) => {
    if (reportType === 'mainCategory') {
      onSelectMainCategory(data);
    }
  });

  return (
    <>
      {_.isEmpty(statisticsLists) ? (
        <div className={style['no-content']}>No available report.</div>
      ) : (
        <div className={style.container}>
          <PieChart
            data={statisticsLists}
            dataKey="total"
            nameKey={`${reportType}Name`}
            width={850}
            height={420}
            outerRadius={135}
            onClickPie={handleSelectMainCategory}
          />
          <TableList
            hoverTableRow={true}
            lists={statisticsLists}
            headCells={headCells}
            onClickTableRow={handleSelectMainCategory}
          />
          {reportType !== 'subCategory' && (
            <TableList
              lists={bugOwnerLists || []}
              headCells={bugOwnerHeadCells}
            />
          )}
        </div>
      )}
    </>
  );
};

ReportDetail.propTypes = {
  selectedMainCategory: PropTypes.shape({
    mainCategoryId: PropTypes.string.isRequired,
    mainCategoryName: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    percentage: PropTypes.string.isRequired,
  }),
  reportType: PropTypes.oneOf(['mainCategory', 'subCategory', 'tool']),
  onSelectMainCategory: PropTypes.func.isRequired,
};

export default ReportDetail;
