import React, { useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import style from './style.scss';
import { Tooltip } from '@tkww/shared';
import ButtonWrapper from '../ButtonWrapper';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles({
  root: {
    tableLayout: 'fixed',
  },
  tableCellHeader: {
    backgroundColor: '#5C6BC0',
    color: '#FFFFFF',
  },
  iconRoot: {
    verticalAlign: 'bottom',
    marginLeft: '5px',
  },
});

export const DataGrid = (props) => {
  const { list = [], getTools, toolStatistics = [], pageType } = props;
  const classes = useStyles();
  const toolsAmountMapping = useMemo(() => {
    const toolsAmount = {};
    list.forEach((l) => {
      const filter = toolStatistics.find((f) => l.name === f.toolName);
      if (filter) toolsAmount[l.id] = filter.total;
    });
    return toolsAmount;
  }, [list, toolStatistics]);

  return (
    <TableContainer component={Paper} className={style['table-contianer']}>
      <Table aria-label="simple table" classes={{ root: classes.root }}>
        <TableHead className={style['custom-table-head']}>
          <TableRow>
            <TableCell>Tool Name</TableCell>
            <TableCell>Feedback Amount</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((data) => (
            <TableRow key={data.id} className={style['custom-table-body-row']}>
              <TableCell component="td" scope="row">
                <div className={style['custom-table-body-col']}>
                  {data.name}
                  {data.description && data.description !== ' ' && (
                    <Tooltip title={data.description}>
                      <ErrorOutlineIcon
                        classes={{ root: style['info-icon'] }}
                      />
                    </Tooltip>
                  )}
                </div>
              </TableCell>
              <TableCell>
                {toolsAmountMapping ? toolsAmountMapping[data.id] || '0' : '0'}
              </TableCell>
              <TableCell>
                <div className={style['button-wrapper']}>
                  <ButtonWrapper
                    toolId={data.id}
                    description={data.description}
                    name={data.name}
                    getTools={getTools}
                    pageType={pageType}
                    amount={
                      toolsAmountMapping
                        ? toolsAmountMapping[data.id] || '0'
                        : '0'
                    }
                  />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
