import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useFetch } from '@tkww/shared';
import { Feedback as FeedbackApi, Settings as SettingsApi } from '@tkww/client';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

const init = {
  toolsMapping: {},
  categoriesMapping: {},
  tools: [],
  categories: [],
  selectedFeedback: {},
  refreshSelectedFeedback: () => {},
};

export const FeedbackContext = createContext(init);

const FeedbackContextProvider = ({ children }) => {
  const [categoriesMapping, setCategoriesMapping] = useState([]);
  const [selectedFeedback, setSelectedFeedback] = useState({});
  const [
    handleFetchSelectedFeedback,
    {
      data: selectedFeedbackResponse,
      isLoading: isLoadingSelected,
      setIsLoading: setIsLoadingSelected,
    },
  ] = useFetch({}, true);
  const [handleFetchCategory, { data: categories }] = useFetch([]);
  const [handleFetchTools, { data: tools }] = useFetch([]);
  const { brand } = useSelector((state) => ({ brand: state.auth.brand }));

  useEffect(() => {
    if (!isEmpty(categories)) {
      const mapping = {};
      categories.forEach((c) => {
        if (c.children.length) {
          c.children.forEach((s) => {
            mapping[s.id] = { ...s, parentId: c.id };
          });
        } else {
          mapping[c.id] = { ...c, parentId: '' };
        }
      });
      setCategoriesMapping(mapping);
    }
  }, [categories]);

  useEffect(() => {
    if (!isEmpty(brand)) {
      const settingsApi = new SettingsApi();
      handleFetchTools(settingsApi.getTools({ brandId: brand.id }));
      handleFetchCategory(settingsApi.getCategories({ brandId: brand.id }));
    }
  }, [brand]);

  useEffect(() => {
    if (!isEmpty(selectedFeedbackResponse)) {
      setSelectedFeedback(selectedFeedbackResponse);
    }
  }, [selectedFeedbackResponse]);

  const refreshSelectedFeedback = useCallback((feedbackId) => {
    const feedbackApi = new FeedbackApi();
    handleFetchSelectedFeedback(feedbackApi.getFeedbackById(feedbackId), {
      openLoading: true,
    });
  }, []);

  return (
    <FeedbackContext.Provider
      value={{
        tools,
        categories,
        categoriesMapping,
        refreshSelectedFeedback,
        isLoadingSelected,
        selectedFeedback,
        setSelectedFeedback,
        setIsLoadingSelected,
      }}
    >
      {children}
    </FeedbackContext.Provider>
  );
};

export default FeedbackContextProvider;
