import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { Select, BtnCheckbox, TextInput } from '@tkww/shared';
import Divider from '@material-ui/core/Divider';
import CancelIcon from '@material-ui/icons/Cancel';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import isEmpty from 'lodash/isEmpty';
import { ConfirmationModal } from '@tkww/shared';
import omit from 'lodash/omit';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FeedbackContext } from '../../context';
import { schemaWithoutBug, schemaWithBug } from './validate';
import style from './style.scss';

const PanelForm = (props) => {
  const {
    isBug,
    owner,
    platforms,
    status,
    categoryId,
    toolId,
    onDelete,
    onSave,
    showDelete,
    storyId,
    notes,
  } = props;
  const [error, setError] = useState({});
  const [formData, setFormData] = useState({
    isBug: isBug || false,
    owner: owner || '',
    issueAppearsOn: platforms,
    status: status === 'Unresolved' ? false : true,
    categoryId,
    toolId: toolId || '',
    storyId: storyId || '',
    notes: notes || '',
  });
  const [openModal, setOpenModal] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);

  const { tools, categories, isLoadingSelected } = useContext(FeedbackContext);

  useEffect(() => {
    const result = [];
    categories.forEach((c) => {
      !isEmpty(c.children) &&
        c.status == 'active' &&
        c.children.forEach((child) => {
          result.push({ name: child.name, id: child.id, groupBy: c.name });
        });
    });
    setCategoryOptions(result);
  }, [categories]);

  const handleClickConfirmationModal = useCallback(
    (action) => {
      if (action.label === 'YES') {
        onDelete();
      }
      setOpenModal(false);
    },
    [onDelete]
  );

  const handleSave = () => {
    let payload = {
      ...formData,
      status: formData.status ? 'Resolved' : 'Unresolved',
    };
    let error = '';

    if (!payload.isBug) {
      payload = omit(payload, ['status', 'owner']);
      error = schemaWithoutBug.validate(payload).error;
    } else {
      error = schemaWithBug.validate(payload).error;
    }

    if (!isEmpty(error)) {
      return setError({
        label: error.details[0].context.label,
        message: error.message,
      });
    }
    onSave(payload);
  };
  const handleFormData = (value) => {
    setError({});
    setFormData({ ...formData, ...value });
  };

  const categoryValue = useMemo(() => {
    const category = categoryOptions.find((c) => c.id === formData.categoryId);
    return category ? category : null;
  }, [formData.categoryId, categoryOptions]);

  return (
    <div className={style['form-container']}>
      <Divider />
      <div className={style['grid-flex']}>
        <Select
          label="Platform"
          options={[
            { label: 'Android', value: 'Android' },
            { label: 'IOS', value: 'iOS' },
            { label: 'Web', value: 'Web' },
          ]}
          onChange={(value) => handleFormData({ issueAppearsOn: [value] })}
          value={(formData.issueAppearsOn && formData.issueAppearsOn[0]) || ''}
          errorMessage="required"
          isError={error.label === 'issueAppearsOn'}
        />
        <Select
          label="Tool"
          options={tools.map((t) => ({ label: t.name, value: t.id }))}
          onChange={(value) => handleFormData({ toolId: value })}
          value={formData.toolId}
          errorMessage="required"
          isError={error.label === 'toolId'}
        />
      </div>
      <div className={style['grid-flex']}>
        <Autocomplete
          id="grouped-demo"
          options={categoryOptions}
          groupBy={(option) => option.groupBy}
          getOptionLabel={(option) => option.name}
          style={{ width: '100%', padding: '0 10px', height: 46 }}
          classes={{
            input: style['autocomplete-input'],
            inputRoot: style['autocomplete-inputRoot'],
          }}
          value={categoryValue}
          renderInput={(params) => (
            <TextField
              {...params}
              error={error.label === 'categoryId'}
              helperText={error.label === 'categoryId' ? 'required' : ''}
              label="Category"
              variant="outlined"
            />
          )}
          onChange={(e, v) => {
            const categoryId = v ? v.id : '';
            handleFormData({ categoryId });
          }}
        />
      </div>
      <div className={style['grid-flex']}>
        <Select
          label="It is a bug."
          options={[
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ]}
          onChange={(value) => {
            handleFormData({ isBug: value });
          }}
          value={formData.isBug}
        />
        {formData.isBug && (
          <Select
            label="Bug Owner"
            options={[
              { label: 'Mobile team', value: 'Mobile team' },
              { label: 'Web team', value: 'Web team' },
            ]}
            onChange={(value) => handleFormData({ owner: value })}
            value={formData.owner}
            errorMessage="required"
            isError={error.label === 'owner'}
          />
        )}
      </div>
      <div className={style['grid']}>
        {formData.isBug && [
          <BtnCheckbox
            key="1"
            label="Bug Resolved"
            setValue={(value) => handleFormData({ status: value })}
            checked={formData.status}
            disabled={!formData.isBug}
          />,
          <TextInput
            key="2"
            label="Bug Story"
            setValue={(value) => handleFormData({ storyId: value })}
            value={formData.storyId}
          />,
        ]}
        <TextInput
          label="Notes"
          setValue={(value) => handleFormData({ notes: value })}
          value={formData.notes}
          isError={error.label === 'notes'}
          errorMessage="over max 100"
        />
      </div>
      <div className={style['button-container']}>
        {showDelete && (
          <div
            className={style['delete-button']}
            onClick={() => setOpenModal(true)}
          >
            <CancelIcon color="primary" />
            <span>Delete this group</span>
          </div>
        )}
        <Button variant="contained" color="primary" onClick={handleSave}>
          SAVE
        </Button>
      </div>
      {isLoadingSelected && (
        <div className={style.loading}>
          <CircularProgress size="24px" />
        </div>
      )}
      {openModal && (
        <ConfirmationModal
          onClose={() => setOpenModal(false)}
          actions={[
            { label: 'NO', variant: 'outlined' },
            { label: 'YES', variant: 'contained' },
          ]}
          content="Are you sure to delete the tag from this brand?"
          onClickAction={handleClickConfirmationModal}
        />
      )}
    </div>
  );
};

export default PanelForm;
