import React from 'react';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import style from './style.scss';

const BaseModal = ({
  open,
  showCloseButton = true,
  children,
  onClose,
  title,
}) => {
  return (
    <Modal
      open={open}
      className={style['base-modal-container']}
      onClose={onClose}
      aria-labelledby="modal-title"
    >
      <div className={style['content-box']}>
        {title && (
          <div id="modal-title" className={style['modal-title']}>
            <strong>{title}</strong>
          </div>
        )}
        {showCloseButton && (
          <CloseIcon
            color="primary"
            className={style['close-button']}
            onClick={onClose}
          />
        )}
        {children}
      </div>
    </Modal>
  );
};

export default BaseModal;
