import { stringify } from 'query-string';
import { Client } from '../client';

export class Settings extends Client {
  async getTools(params) {
    return await fetch(`${this.host}/tools?${stringify(params)}`, {
      method: 'GET',
      headers: this.headers,
    });
  }

  async postTools(body) {
    return await fetch(`${this.host}/tools`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify(body),
    });
  }

  async putTools({ params, body }) {
    return await fetch(`${this.host}/tools/${params.id}`, {
      method: 'PUT',
      headers: this.headers,
      body: JSON.stringify(body),
    });
  }

  async deletTools(id) {
    return await fetch(`${this.host}/tools/${id}`, {
      method: 'DELETE',
      headers: this.headers,
    });
  }

  async archiveTools(id) {
    return await fetch(`${this.host}/tools/${id}/archive`, {
      method: 'POST',
      headers: this.headers,
    });
  }

  async activateTools(id) {
    return await fetch(`${this.host}/tools/${id}/activate`, {
      method: 'POST',
      headers: this.headers,
    });
  }

  async getCategories(params) {
    return await fetch(
      `${this.host}/categories?${stringify({
        includeArchived: true,
        ...params,
      })}`,
      {
        method: 'GET',
        headers: this.headers,
      }
    );
  }

  async createCategory(payload) {
    return await fetch(`${this.host}/categories`, {
      method: 'POST',
      headers: this.headers,
      body: JSON.stringify(payload),
    });
  }

  async archiveCategory(id) {
    return await fetch(`${this.host}/categories/${id}/archive`, {
      method: 'POST',
      headers: this.headers,
    });
  }

  async updateCategory(id, payload) {
    return await fetch(`${this.host}/categories/${id}`, {
      method: 'PUT',
      headers: this.headers,
      body: JSON.stringify(payload),
    });
  }

  async deleteCategory(id) {
    return await fetch(`${this.host}/categories/${id}`, {
      method: 'DELETE',
      headers: this.headers,
    });
  }

  async activateCategory(id) {
    return await fetch(`${this.host}/categories/${id}/activate`, {
      method: 'POST',
      headers: this.headers,
    });
  }
}
