import React, { useState, useEffect } from 'react';
import { Brand, Project } from '@tkww/client';
import { useFetch, TablePageHeader } from '@tkww/shared';
import { dataMapping } from './helper';
import BrandModal from './BrandModal';
import PlatformModal from './ProjectModal';
import Button from '@material-ui/core/Button';
import BrandList from './BrandList';
import style from './style.scss';

const Brands = () => {
  const [brand, setBrand] = useState({});
  const [project, setProject] = useState({});
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false);
  const [brandModalOpen, setBrandModalOpen] = useState(false);
  const [projectModalOpen, setProjectModalOpen] = useState(false);
  const [handleFetchBrands, { data: brands }] = useFetch([]);
  const [handleFetchProjects, { data: projects }] = useFetch([]);
  const brandClient = new Brand();
  const projectClient = new Project();

  const handleFetchData = () => {
    handleFetchBrands(brandClient.getBrands());
    handleFetchProjects(projectClient.getByCondition());
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  useEffect(() => {
    if (reload) {
      handleFetchData();
      setReload(false);
    }
  }, [reload]);

  useEffect(() => {
    if (brands.length > 0 && projects.length > 0) {
      setData(dataMapping(brands, projects));
    }
  }, [brands, projects]);

  const handleClickAddBrand = () => {
    setBrand({});
    setBrandModalOpen(true);
  };

  const handleClickAddPlatform = () => {
    setProject({});
    setProjectModalOpen(true);
  };

  return (
    <>
      <div className={style['header']}>
        <span className={style.title}>Brand and Platform Management</span>
        <TablePageHeader>
          <div>
            {brands.length} brand
            {brands.length === 1 ? '' : 's'} in AppInsights
          </div>
          <div>
            <Button
              className={style['add-button']}
              variant="contained"
              color="primary"
              onClick={handleClickAddBrand}
            >
              ADD BRAND
            </Button>
            <Button
              className={style['add-button']}
              variant="contained"
              color="primary"
              onClick={handleClickAddPlatform}
            >
              ADD PLATFORM
            </Button>
          </div>
        </TablePageHeader>
      </div>
      <div>
        <BrandList
          brands={data}
          setBrand={setBrand}
          setProject={setProject}
          setBrandModalOpen={setBrandModalOpen}
          setProjectModalOpen={setProjectModalOpen}
        />
      </div>
      <BrandModal
        open={brandModalOpen}
        setModalOpen={setBrandModalOpen}
        brand={brand}
        setReload={setReload}
      />
      <PlatformModal
        open={projectModalOpen}
        setModalOpen={setProjectModalOpen}
        brands={data}
        project={project}
        setReload={setReload}
      />
    </>
  );
};

export default Brands;
