import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useFetch } from '@tkww/shared';
import PropTypes from 'prop-types';
import { Auth } from '@tkww/client';
import { DeleteModal } from './modal';
import EmailIcon from '@material-ui/icons/Email';
import { Tooltip } from '@material-ui/core';

export const MoreOptionsMenu = (props) => {
  const [ResendInviteUser, { isLoading }] = useFetch();
  const { token } = useSelector((state) => state.auth);
  const auth = new Auth({
    token,
  });

  const handleReinvite = async () => {
    const payload = {
      role: props.role,
      email: props.email,
    };

    ResendInviteUser(auth.inviteUser(payload), {
      openLoading: true,
      success: {
        text: 'Resend invite user email succuss!',
      },
    });
  };

  return (
    <>
      {!props.hasSignup &&
        (isLoading ? (
          <CircularProgress className={props.classes} size="24px" />
        ) : (
          <Tooltip title="Resend Invite">
            <EmailIcon
              color="primary"
              className={props.classes}
              onClick={() => handleReinvite()}
            />
          </Tooltip>
        ))}
      <DeleteModal userId={props.userId} className={props.classes} />
    </>
  );
};

MoreOptionsMenu.propTypes = {
  role: PropTypes.string,
  id: PropTypes.string,
  email: PropTypes.string,
  classes: PropTypes.string,
};
