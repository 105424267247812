import moment from 'moment';

import {
  autoCompleteFields,
  boolFields,
  multipleFields,
  stringDataKeys,
} from '@tkww/feedback/constants';
import { filterFormat } from '../Feedback/actions';
import styles from './styles.scss';

export const getClasses = {
  AccordionClasses: {
    root: styles['accordion-container'],
    disabled: styles['accordion-disabled'],
  },
  AccordionSummaryClasses: {
    root: styles['accordion-summary-container'],
    content: styles['accordion-summary-content'],
    disabled: styles['accordion-summary-disabled'],
    expanded: styles['accordion-summary-content-expanded'],
  },
  AccordionDetailsClasses: {
    root: styles['accordion-details-container'],
  },
};

export const checkDefaultFieldChanged = (formData) => {
  const fieldChanged = {};
  const defaultField = filterFormat();
  autoCompleteFields.forEach((key) => {
    JSON.stringify(formData[key].sort()) !==
      JSON.stringify(defaultField[key].sort()) && (fieldChanged[key] = true);
  });
  boolFields.forEach((key) => {
    formData[key] !== defaultField[key] && (fieldChanged[key] = true);
  });
  multipleFields.forEach((key) => {
    JSON.stringify(formData[key].sort()) !==
      JSON.stringify(defaultField[key].sort()) && (fieldChanged[key] = true);
  });
  stringDataKeys.forEach((key) => {
    formData[key] !== defaultField[key] && (fieldChanged[key] = true);
  });
  formData.startDate !== defaultField.startDate &&
    (fieldChanged.startDate = true);
  formData.endDate !== defaultField.endDate && (fieldChanged.endDate = true);

  return fieldChanged;
};

export const handleFilterFormData = (formData) => {
  const data = {};
  Object.keys(formData).forEach((key) => {
    if (key === 'startDate' || key === 'endDate') {
      data[key] = moment(formData[key]).format('YYYY-MM-DD');
    } else if (
      (typeof formData[key] === 'string' && formData[key]) ||
      (Array.isArray(formData[key]) &&
        formData[key].length > 0 &&
        !formData[key].includes('-1')) ||
      (typeof formData[key] === 'boolean' && formData[key])
    ) {
      data[key] = formData[key];
    }
  });

  return data;
};
