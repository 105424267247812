import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from '@material-ui/core';
import style from './style.scss';

const TabBar = (props) => {
  const { width, items, selectIndex, onChange } = props;

  const [selectedTabIndex, setSelectedTabIndex] = useState(selectIndex || 0);

  const handleClickTab = (tabIndex, item) => {
    setSelectedTabIndex(tabIndex);
    onChange(tabIndex, item);
  };

  useEffect(() => {
    setSelectedTabIndex(selectIndex || 0);
  }, [selectIndex]);

  return (
    <div className={style.container} style={{ width }}>
      <ButtonGroup disableElevation variant="contained" fullWidth>
        {items.map((item, index) => (
          <Button
            key={index}
            color={selectedTabIndex === index ? 'primary' : ''}
            onClick={() => handleClickTab(index, item)}
            // className={style.button}
            classes={{ root: style.button }}
          >
            {item.label}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
};

TabBar.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      data: PropTypes.any,
    })
  ).isRequired,
  selectIndex: PropTypes.number,
  onChange: PropTypes.func,
  width: PropTypes.string,
};

export default TabBar;
