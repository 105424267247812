import React from 'react';
import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import styles from './styles.scss';

const BtnCheckbox = (props) => {
  const {
    name,
    label,
    checked,
    setValue,
    disabled,
    onChange,
    focused = false,
  } = props;

  const handleFormControlLabelChange = () => {
    setValue(!checked);
    onChange && onChange();
  };

  return (
    <Button
      variant="outlined"
      className={styles['btn-checkbox-container']}
      classes={{
        label: styles['btn-checkbox-label'],
        root: focused && styles['btn-checkbox-focused'],
      }}
    >
      <FormControlLabel
        className={styles['form-control-label']}
        value={1}
        checked={checked}
        name={name}
        disabled={disabled}
        onChange={handleFormControlLabelChange}
        control={
          <Checkbox className={styles['checkbox-container']} color="primary" />
        }
        label={label}
        labelPlacement="start"
      />
    </Button>
  );
};

export default BtnCheckbox;
