import * as actions from '../constants';

const ACTION_HANDLERS = {
  [actions.SET_RELOAD_COLLABORATORS]: (state, { data }) => {
    return {
      ...state,
      reloadCollaborators: data.reloadCollaborators,
    };
  },
};

const initialState = {
  reloadCollaborators: false,
};

export const settingReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
