import Cookies from 'js-cookie';
import { SECURITY_SERVICE, QA_SECURITY_SERVICE } from './urls';

export class Client {
  constructor({
    apikey,
    host = {
      qa: QA_SECURITY_SERVICE,
      production: SECURITY_SERVICE,
    },
    env = process.env.REACT_APP_BUILD_ENV === 'production'
      ? 'production'
      : 'qa',
  } = {}) {
    this.apikey = apikey;
    this.host = host[env];
    this.token = Cookies.get('authToken') || null;
    this.headers = {
      Authorization: `Bearer ${this.token}`,
    };
  }

  filterRemoveEmpty(data = {}, isRemoveFalse = true) {
    const newData = {};
    Object.keys(data).forEach((key) => {
      switch (true) {
        case Boolean(typeof data[key] === 'string' && data[key]):
        case typeof data[key] === 'boolean':
          if (!isRemoveFalse || (isRemoveFalse && data[key])) {
            newData[key] = data[key];
          }
          break;
        case Array.isArray(data[key]) && data[key].length > 0:
          if (!data[key].includes('-1')) {
            newData[key] = data[key];
          } else {
            newData[key] = [];
          }
          break;
        default:
          break;
      }
    });

    return newData;
  }
}
