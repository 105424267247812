import React, { useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
} from '@material-ui/core';
import { getRenderValue, handleChange, optionsFormat } from './actions';
import styles from './styles.scss';

const Selects = (props) => {
  const {
    name,
    label,
    value,
    setValue,
    options = [],
    disabled,
    multiple,
    onChange,
    renderValue,
    focused,
  } = props;

  useEffect(() => {
    if (multiple && (!value || !value.length)) setValue(['-1']);
  }, [value, setValue, multiple]);

  const handleSelectChange = (event) =>
    handleChange(event.target.value, {
      oldValue: value,
      setValue,
      onChange,
      multiple,
    });

  const valueRender = (event) =>
    getRenderValue(event, {
      renderValue,
      multiple,
      options: optionsFormat(options),
    });

  return (
    <div className={styles['selects-container']}>
      <FormControl
        variant="outlined"
        className={styles['form-control']}
        focused={focused}
      >
        <InputLabel className={styles['selects-label']}>{label}</InputLabel>
        <Select
          name={name}
          value={value}
          onChange={handleSelectChange}
          label={label}
          disabled={disabled}
          multiple={multiple}
          renderValue={valueRender}
          className={styles['field-select-container']}
        >
          {optionsFormat(options, multiple, label).map((item) => (
            <MenuItem
              key={item.value}
              value={item.value}
              disabled={item.disabled}
            >
              {multiple && (
                <Checkbox
                  color="primary"
                  checked={value && value.indexOf(item.value) > -1}
                />
              )}
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default Selects;
