import React, { useContext, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import { useFetch } from '@tkww/shared';
import { Feedback as FeedbackApi } from '@tkww/client';
import isEmpty from 'lodash/isEmpty';
import CircularProgress from '@material-ui/core/CircularProgress';
import FeedbackList from '../FeedbackList';
import FilterSection from '../FilterSection';
import Panel from '../Panel';
import Pagination from '../Pagination';
import * as action from '../../constants';
import { getFilters } from './actions';
import FeedbackContextProvider, { FeedbackContext } from '../../context';
import style from './style.scss';

const watchedLimit = 10000;

export const Main = (props) => {
  const { create = false } = props;
  const [isOpenPanel, setIsOpenPanel] = useState(false);
  const [query, setQuery] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const feedbackListRef = useRef(null);
  const {
    refreshSelectedFeedback,
    selectedFeedback,
    isLoadingSelected,
    setSelectedFeedback,
  } = useContext(FeedbackContext);
  const [
    handleFetchFeedbacks,
    { data: feedbackResponse, isLoading },
  ] = useFetch({}, !create && true);
  const [
    handleFetchWatchedFeedback,
    { data: watchedFeedbacksResponse },
  ] = useFetch({});
  const feedbackApi = new FeedbackApi();
  const filter = props.match.params.filter;
  const { search } = useLocation();
  const { user, projectIds } = useSelector((state) => ({
    user: state.auth.user,
    projectIds: state.auth.projectIds,
  }));
  const defaultFilterData = getFilters(
    filter,
    user ? user.filters : [],
    search
  );

  useEffect(() => {
    setIsOpenPanel(false);
    setCurrentPage(1);
    if ((!create || (create && search)) && user && projectIds) {
      const { isDefault, filterData } = getFilters(
        filter,
        user ? user.filters : [],
        search
      );
      const feedbackApi = new FeedbackApi();
      const formData = isDefault
        ? action.defaultFilter[filter.toLowerCase()]
        : filterData.options;
      const data = { ...formData, projectIds };

      // sync label and labels
      data.labels = data.label;
      delete data.label;

      setQuery(data);
      if (!data.watched) {
        handleFetchWatchedFeedback(
          feedbackApi.getFeedbacks(
            {
              limit: watchedLimit,
              data: { ...data, watched: true },
            },
            !isDefault
          )
        );
      }
      handleFetchFeedbacks(feedbackApi.getFeedbacks({ data }, !isDefault), {
        openLoading: true,
      });
    }
  }, [create, filter, projectIds, search, user]);

  const handleDrawer = (isOpen) => (selectData) => {
    setIsOpenPanel(isOpen);
    if (isOpen) {
      setSelectedFeedback({ id: selectData.id });
      refreshSelectedFeedback(selectData.id);
    } else if (
      (filter === 'untagged' && !isEmpty(selectedFeedback.tags)) ||
      (filter === 'tagged' && isEmpty(selectedFeedback.tags))
    ) {
      handleFetchFeedbacks(
        feedbackApi.getFeedbacks(
          { page: currentPage, data: query },
          !defaultFilterData.isDefault
        ),
        {
          openLoading: true,
        }
      );
    }
  };

  const handlePage = (page) => {
    setIsOpenPanel(false);
    setCurrentPage(page);
    if (!query.watched) {
      handleFetchWatchedFeedback(
        feedbackApi.getFeedbacks(
          {
            limit: watchedLimit,
            data: { ...query, watched: true },
          },
          !defaultFilterData.isDefault
        )
      );
    }
    handleFetchFeedbacks(
      feedbackApi.getFeedbacks(
        { page, data: query },
        !defaultFilterData.isDefault
      ),
      {
        openLoading: true,
      }
    );
    feedbackListRef.current.scrollTo(0, 0);
  };

  return (
    <div ref={feedbackListRef} className={style['feedback-main-container']}>
      <main className={style.content}>
        <FilterSection
          create={defaultFilterData.filterData.create}
          isDefault={defaultFilterData.isDefault}
          filterData={defaultFilterData.filterData}
        />
        {!create && (
          <>
            {feedbackResponse && feedbackResponse.pageContext && (
              <div className={style['tips-container']}>
                <span>
                  {(feedbackResponse.pageContext.page - 1) *
                    feedbackResponse.pageContext.pageCount +
                    1}
                  -
                  {feedbackResponse.pageContext.page *
                    feedbackResponse.pageContext.pageCount}{' '}
                  of total {feedbackResponse.pageContext.total} feedback
                </span>
              </div>
            )}
            {feedbackResponse && feedbackResponse.items && (
              <FeedbackList
                list={feedbackResponse && feedbackResponse.items}
                handleDrawer={handleDrawer(true)}
                isOpenPanel={isOpenPanel}
                watchedList={
                  (watchedFeedbacksResponse &&
                    watchedFeedbacksResponse.items) ||
                  []
                }
                query={query}
              />
            )}
            {feedbackResponse && feedbackResponse.pageContext && (
              <Pagination
                count={feedbackResponse.pageContext.pageCount}
                handleChange={handlePage}
              ></Pagination>
            )}
          </>
        )}
        {isLoading && (
          <div className={style.loading}>
            <CircularProgress size="24px" />
          </div>
        )}
      </main>
      <Drawer
        className={style['drawer']}
        anchor="right"
        open={isOpenPanel}
        classes={{
          paper: style['drawer-paper'],
          backdrop: style['backdrop'],
          root: style['drawer-root'],
        }}
        onClose={handleDrawer(false)}
      >
        {isOpenPanel && (
          <Panel
            isLoadingSelected={isLoadingSelected}
            selectedFeedback={selectedFeedback}
          />
        )}
      </Drawer>
    </div>
  );
};

export const Feedback = (props) => {
  return (
    <FeedbackContextProvider>
      <Main {...props}></Main>
    </FeedbackContextProvider>
  );
};
