import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SET_RELOAD_COLLABORATORS } from '../../constants';
import { User } from '@tkww/client';
import { useFetch } from '@tkww/shared';
import { BrandUserList } from './BrandUserList';
import { InviteUserModal } from './InviteUserModal';
import { Button } from '@material-ui/core';
import style from './style.scss';

const Collaborators = () => {
  const { brand, user, reloadCollaborators } = useSelector((state) => ({
    brand: state.auth.brand,
    user: state.auth.user,
    reloadCollaborators: state.setting.reloadCollaborators,
  }));
  const [modalDisplay, setModalDisplay] = useState(false);
  const [handleFetch, { data: brandUsers }] = useFetch([]);
  const client = new User();
  const dispatch = useDispatch();

  const handleFetchData = () => {
    handleFetch(client.getBrandUsers(brand.id));
  };

  useEffect(() => {
    if (brand) {
      handleFetchData();
    }
  }, [brand]);

  useEffect(() => {
    if (reloadCollaborators) {
      handleFetchData();
      dispatch({
        type: SET_RELOAD_COLLABORATORS,
        data: { reloadCollaborators: false },
      });
    }
  }, [reloadCollaborators]);

  return (
    brand && (
      <div className={style['data-container']}>
        <div className={style['data-header']}>
          <div className={style['data-count']}>
            {`${brandUsers.length} people in this brand.`}
          </div>
          <div className={style['invite-button']}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setModalDisplay(true)}
            >
              INVITE PEOPLE
            </Button>
          </div>
        </div>
        <div className={style['data-list']}>
          <BrandUserList
            brandUsers={brandUsers}
            brandId={brand.id}
            currentUserId={user.id}
          />
        </div>
        {modalDisplay && (
          <InviteUserModal
            setModalDisplay={setModalDisplay}
            brandId={brand.id}
          />
        )}
      </div>
    )
  );
};

export default Collaborators;
