/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import {
  AppBar,
  Drawer,
  Toolbar,
  IconButton,
  MenuItem,
  Menu,
  Select,
  List,
  ListItem,
  Collapse,
  Checkbox,
} from '@material-ui/core';
import {
  Star,
  Settings,
  AddSharp,
  ClearSharp,
  ArrowDropDownSharp as SortDownIcon,
  ArrowRightSharp,
  PieChartSharp,
  Build,
} from '@material-ui/icons';
import style from './style.scss';
import { useFetch } from '@tkww/shared';
import { Auth, Project, Filter, User } from '@tkww/client';
import {
  GET_CURRENT_USER_SUCCESS,
  SET_PLATFORM,
  SET_BRAND,
  REMOVE_FILTER_SUCCESS,
  GET_PROJECTS_SUCCESS,
  USER_SELECTED_BRAND_PLATFORMS,
} from './constant';
import { cloneDeep, filter, find, sortBy, isEmpty, assign, get } from 'lodash';
import { makeStyles } from '@material-ui/styles';
import ConfirmationModal from '@tkww/shared/components/ConfirmationModal';
import Avatar from './Avatar';

const useStyles = makeStyles({
  selected: {
    backgroundColor: '#4458B5 !important',
  },
  'feedback-selected': {
    backgroundColor: 'transparent !important',
    color: '#8197FF',
  },
});

export const Layout = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    auth: { user, brand, platforms },
    project: { projects },
  } = useSelector((state) => state);
  const ref = useRef({ projects, removeFilterId: '' });
  const [openFeedbacks, setOpenFeedbacks] = useState(
    history.location.pathname.includes('feedback')
  );
  const authClient = new Auth();
  const projectClient = new Project();
  const filterClient = new Filter();
  const userClient = new User();
  const [getRoleFeatures, { data: roleFeatures }] = useFetch();
  const [currentUser, { data: currentUserData }] = useFetch();
  const [getProjects, { data: fetchedProjects }] = useFetch();
  const [changeBrand] = useFetch();
  const classes = useStyles();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const getUserPreferenceById = (userId) => {
    const preference = Cookies.get(
      `${USER_SELECTED_BRAND_PLATFORMS}_${userId}`
    );
    return preference ? JSON.parse(preference) : null;
  };

  const setUserPreference = (userId, preference) => {
    const originalPreference = getUserPreferenceById(userId);
    const cookieKey = `${USER_SELECTED_BRAND_PLATFORMS}_${userId}`;
    if (originalPreference) {
      Cookies.set(
        cookieKey,
        JSON.stringify(assign({}, originalPreference, preference))
      );
    } else {
      Cookies.set(cookieKey, JSON.stringify(preference));
    }
  };

  const handleChangePlatforms = (platforms) => {
    if (!isEmpty(platforms)) {
      let projectIds = [];
      get(ref, 'current.projects', []).forEach((project) => {
        if (platforms.includes(project.platform)) {
          projectIds.push(project.id);
        }
      });
      dispatch({
        type: SET_PLATFORM,
        data: { platforms, projectIds },
      });
      setUserPreference(user.id, { platforms });
    }
  };

  useEffect(() => {
    if (!user) {
      currentUser(authClient.currentUser());
    }
    getProjects(projectClient.getByCondition({ includeArchived: true }));
    getRoleFeatures(userClient.getRoleFeatures());
  }, []);

  useEffect(() => {
    if (currentUserData) {
      dispatch({
        type: GET_CURRENT_USER_SUCCESS,
        data: currentUserData,
      });
    }
  }, [currentUserData]);

  useEffect(() => {
    if (fetchedProjects) {
      dispatch({
        type: GET_PROJECTS_SUCCESS,
        data: fetchedProjects,
      });
    }
  }, [fetchedProjects]);

  useEffect(() => {
    if (user && projects) {
      const defaultBrand = user.brands[0];
      const userPreference = getUserPreferenceById(user.id);
      handleChangeBrand(
        userPreference && userPreference.brand
          ? userPreference.brand.id
          : defaultBrand.id,
        false
      );
    }
  }, [user, projects]);

  const handleChangeBrand = (brandId, noticeServer) => {
    const userProjects = sortBy(filter(projects, { brandId }), 'platform');
    const brand = find(user.brands, { id: brandId });
    ref.current.projects = userProjects;
    dispatch({ type: SET_BRAND, data: brand });
    const userPreference = getUserPreferenceById(user.id);
    handleChangePlatforms(
      userPreference && userPreference.platforms
        ? userPreference.platforms
        : [userProjects[0].platform]
    );
    noticeServer && changeBrand(authClient.changeBrand(brandId));
    setUserPreference(user.id, { brand });
  };

  const handleFeedbacksClick = () => {
    setOpenFeedbacks(!openFeedbacks);
  };

  const handleClickConfirmationModal = async ({ label }) => {
    if (label === 'YES') {
      const removeFilterId = ref.current.removeFilterId;
      const response = await filterClient.removeFilter(removeFilterId);
      if (response.status === 200) {
        const cloneUser = cloneDeep(user);
        cloneUser.filters = user.filters.filter((f) => f.id !== removeFilterId);
        dispatch({
          type: REMOVE_FILTER_SUCCESS,
          data: cloneUser,
        });
      }
    }
    setOpenConfirmationModal(false);
  };

  const handleRemoveFilter = (id) => {
    ref.current.removeFilterId = id;
    setOpenConfirmationModal(true);
  };

  return (
    <div className={style['layout-container']}>
      <AppBar position="static">
        <Toolbar className={style['tool-bar']}>
          {user && <Avatar user={user} />}
        </Toolbar>
      </AppBar>
      <Drawer
        className={style.drawer}
        variant="permanent"
        classes={{
          paper: style['drawer-paper'],
        }}
        anchor="left"
      >
        <div className={style['drawer-content-container']}>
          <div className={style['menu-title']}>
            <div className={style['site-title']}>APP INSIGHTS</div>
            <div>
              <Select
                classes={{ select: style.select, icon: style.icon }}
                value={brand ? brand.id : ''}
                onChange={(e) => handleChangeBrand(e.target.value, true)}
              >
                {user &&
                  user.brands.map((brand, index) => (
                    <MenuItem key={index} value={brand.id}>
                      {brand.name}
                    </MenuItem>
                  ))}
              </Select>
            </div>
            <div>
              <Select
                classes={{ select: style.select, icon: style.icon }}
                value={platforms}
                renderValue={(platforms) => platforms.join(', ')}
                multiple
                onChange={(e) => handleChangePlatforms(e.target.value)}
              >
                {ref.current.projects &&
                  ref.current.projects.map((project, index) => (
                    <MenuItem key={index} value={project.platform}>
                      <Checkbox
                        checked={platforms.indexOf(project.platform) > -1}
                      />
                      {project.platform}
                    </MenuItem>
                  ))}
              </Select>
            </div>
          </div>
          <ListItem
            className={style['feedbacks']}
            classes={{ selected: classes.selected }}
            selected={history.location.pathname === '/statistics'}
            onClick={() => history.push('/statistics')}
          >
            <PieChartSharp className={style['item-icon']} />
            Statistics
          </ListItem>
          <ListItem
            button
            classes={{ selected: classes['feedback-selected'] }}
            selected={history.location.pathname.includes('feedback')}
            className={style['feedbacks']}
            onClick={handleFeedbacksClick}
          >
            <Star className={style['item-icon']} />
            Feedback
            {openFeedbacks ? (
              <SortDownIcon className={style['expand-icon']} />
            ) : (
              <ArrowRightSharp className={style['expand-icon']} />
            )}
          </ListItem>
          <Collapse in={openFeedbacks} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                classes={{ selected: classes.selected }}
                selected={history.location.pathname === '/feedback/all'}
                className={style['feedback-item']}
                onClick={() => history.push('/feedback/all')}
              >
                All
              </ListItem>
              <ListItem
                button
                classes={{ selected: classes.selected }}
                selected={history.location.pathname === '/feedback/untagged'}
                className={style['feedback-item']}
                onClick={() => history.push('/feedback/untagged')}
              >
                Untagged
              </ListItem>
              <ListItem
                button
                classes={{ selected: classes.selected }}
                selected={history.location.pathname === '/feedback/tagged'}
                className={style['feedback-item']}
                onClick={() => history.push('/feedback/tagged')}
              >
                Tagged
              </ListItem>
              <ListItem
                button
                classes={{ selected: classes.selected }}
                selected={history.location.pathname === '/feedback/watched'}
                className={style['feedback-item']}
                onClick={() => history.push('/feedback/watched')}
              >
                Watched
              </ListItem>
              <ListItem
                button
                classes={{ selected: classes.selected }}
                selected={['/feedback/new-filter', '/feedback'].includes(
                  history.location.pathname
                )}
                className={style['feedback-item']}
                onClick={() => history.push('/feedback/new-filter')}
              >
                New Filter
                <AddSharp className={style['add-icon']} />
              </ListItem>
              {user &&
                user.filters.map((filter, index) => (
                  <ListItem
                    key={index}
                    button
                    classes={{ selected: classes.selected }}
                    selected={
                      history.location.pathname === `/feedback/${filter.id}`
                    }
                    id={filter.id}
                    className={style['feedback-item']}
                  >
                    <div
                      className={style['filter-item']}
                      onClick={() => history.push(`/feedback/${filter.id}`)}
                    >
                      {filter.name}
                    </div>
                    <ClearSharp
                      onClick={() => handleRemoveFilter(filter.id)}
                      className={style['clear-icon']}
                    />
                  </ListItem>
                ))}
            </List>
          </Collapse>
          {roleFeatures &&
            roleFeatures.features.length &&
            roleFeatures.features.map(
              (feature, index) =>
                feature.accessible && (
                  <ListItem
                    key={index}
                    className={style['feedbacks']}
                    classes={{ selected: classes.selected }}
                    selected={history.location.pathname === `/${feature.name}`}
                    onClick={() => history.push(`/${feature.name}`)}
                  >
                    {feature.name === 'settings' ? (
                      <Settings className={style['item-icon']} />
                    ) : (
                      <Build className={style['item-icon']} />
                    )}
                    {feature.displayName}
                  </ListItem>
                )
            )}
        </div>
      </Drawer>
      <main>{children}</main>
      {openConfirmationModal && (
        <ConfirmationModal
          content={`Are you sure you want to delete the selected filter?`}
          actions={[
            { label: 'NO', variant: 'outlined' },
            { label: 'YES', variant: 'contained' },
          ]}
          onClickAction={handleClickConfirmationModal}
          onClose={() => setOpenConfirmationModal(false)}
        />
      )}
    </div>
  );
};
