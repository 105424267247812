import { stringify } from 'query-string';
import { Client } from '../client';

export class Project extends Client {
  async getByCondition(condition) {
    const query = condition ? `?${stringify(condition)}` : '';
    return fetch(`${this.host}/projects${query}`, {
      headers: this.headers,
    });
  }

  async createProject(payload) {
    return fetch(`${this.host}/projects`, {
      method: 'POST',
      headers: {
        ...this.headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
  }

  async updateProject(projectId, payload) {
    return fetch(`${this.host}/projects/${projectId}`, {
      method: 'PUT',
      headers: {
        ...this.headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
  }
}
