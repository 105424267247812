export const optionsFormat = (options, multiple = false) => {
  if (multiple && !options.find((item) => item.value === '-1')) {
    options.unshift({ value: '-1', label: 'All' });
  }
  return options;
};

export const handleChange = (
  newValue,
  { oldValue, setValue, onChange, multiple }
) => {
  if (multiple) {
    if (oldValue.indexOf('-1') === -1 && newValue.indexOf('-1') > -1) {
      setValue(['-1']);
    } else if (oldValue.indexOf('-1') > -1) {
      const allIndex = newValue.indexOf('-1');
      if (allIndex > -1 && newValue.length > 1) {
        newValue.splice(allIndex, 1);
      }
      setValue(newValue);
    } else {
      setValue(newValue);
    }
  } else {
    setValue(newValue);
  }
  onChange && onChange();
};

export const getRenderValue = (values, { renderValue, multiple, options }) => {
  if (renderValue) renderValue(values);
  else {
    if (!multiple) values = [values];
    const valArr = values.map((val) => {
      const option = options.find((item) => item.value === val);
      return option ? option.label : '';
    });
    return valArr.join(',');
  }
};
