import React from 'react';
import Cookies from 'js-cookie';

export const withAuthCheck = (WrappedComponent) => {
  class AuthenticatedComponent extends React.Component {
    componentDidMount() {
      this.performAuthCheck();
    }

    async performAuthCheck() {
      const tokenInCookie = Cookies.get('authToken');

      if (!tokenInCookie) {
        return this.routeToLoginPage();
      }
    }

    routeToLoginPage() {
      this.props.history.push({
        pathname: '/login',
        search: `?returnUrl=${encodeURIComponent(window.location.href)}`,
      });
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return AuthenticatedComponent;
};
