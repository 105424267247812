import { capitalize } from 'lodash';

const getStatisticsTotalMapping = (feedbackStatistics) => {
  const statisticsMap = {};
  feedbackStatistics.forEach((statistic) => {
    statisticsMap[statistic.subCategoryName] = Number(statistic.total);
  });
  return statisticsMap;
};

const initUICategory = (category) => ({
  id: category.id,
  name: category.name,
  description: category.description,
  status: category.status,
  children: [],
  feedbackAmount: 0,
  disabled: false,
});

const getUICategories = (category, statisticsMapping) => {
  let activatedCategory;
  let archivedCategory;
  if (category.status === 'active') {
    activatedCategory = initUICategory(category);
  } else {
    archivedCategory = initUICategory(category);
  }
  category.children.forEach((subCategory) => {
    subCategory.parentId = category.id;
    subCategory.feedbackAmount = statisticsMapping[subCategory.name || ''] || 0;
    if (subCategory.status === 'active') {
      if (!activatedCategory) activatedCategory = initUICategory(category);
      activatedCategory.children.push(subCategory);
      activatedCategory.feedbackAmount += subCategory.feedbackAmount;
    } else {
      if (!archivedCategory) archivedCategory = initUICategory(category);
      archivedCategory.children.push(subCategory);
      archivedCategory.feedbackAmount += subCategory.feedbackAmount;
    }
  });
  if (
    archivedCategory &&
    archivedCategory.status === 'active' &&
    archivedCategory.children.length > 0
  ) {
    archivedCategory.disabled = true;
  }
  return { activatedCategory, archivedCategory };
};

export const covertToUICategories = (categories, feedbackStatistics) => {
  let activatedCategories = {
    total: 0,
    categories: [],
  };
  let archivedCategories = {
    total: 0,
    categories: [],
  };
  const statisticsMapping = getStatisticsTotalMapping(feedbackStatistics);
  categories.forEach((category) => {
    const { activatedCategory, archivedCategory } = getUICategories(
      category,
      statisticsMapping
    );
    if (activatedCategory) {
      activatedCategories.total += 1 + activatedCategory.children.length;
      activatedCategories.categories.push(activatedCategory);
    }
    if (archivedCategory) {
      archivedCategories.total += 1 + archivedCategory.children.length;
      archivedCategories.categories.push(archivedCategory);
    }
  });
  return { activatedCategories, archivedCategories };
};

export const getMessageOption = (action, moduleName, showFail = true) => {
  const messageOption = {};
  messageOption['success'] = {
    text: `${capitalize(action)} ${moduleName} successfully`,
  };
  if (showFail) {
    messageOption['failure'] = {
      text: `${capitalize(action)} ${moduleName} failed`,
    };
  }
  return messageOption;
};
