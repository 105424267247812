import { GET_PROJECTS_SUCCESS } from '../constants';

const ACTION_HANDLERS = {
  [GET_PROJECTS_SUCCESS]: (state, { data }) => ({
    ...state,
    projects: data,
  }),
};

const initialState = {
  projects: null,
};

export const projectReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
