import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
} from '@material-ui/core';
import { stableSort, getComparator } from '@tkww/shared';
import style from './style.scss';

export const TableList = ({
  lists = [],
  headCells = [],
  hoverTableRow = false,
  onClickTableRow,
}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  return (
    <TableContainer component={Paper}>
      <Table className={style['table']}>
        <TableHead className={style['table-header']}>
          <TableRow>
            {headCells.map(({ field, sortable = false, headerName }, index) => (
              <TableCell
                key={index}
                sortDirection={orderBy === field ? order : false}
              >
                {sortable ? (
                  <TableSortLabel
                    active={orderBy === field}
                    direction={orderBy === field ? order : 'asc'}
                    onClick={createSortHandler(field)}
                    classes={{ root: style['sort-by'], icon: style['icon'] }}
                  >
                    {headerName}
                    {orderBy === field ? <span></span> : null}
                  </TableSortLabel>
                ) : (
                  headerName
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(lists, getComparator(order, orderBy)).map(
            (list, index) => {
              return (
                <TableRow
                  hover={hoverTableRow}
                  key={index}
                  onClick={(e) => onClickTableRow && onClickTableRow(list)}
                >
                  {headCells.map((cell, index) => (
                    <TableCell key={index}>{list[cell.field]} </TableCell>
                  ))}
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

TableList.propTypes = {
  lists: PropTypes.array,
  headCells: PropTypes.array,
  hoverTableRow: PropTypes.bool,
  onClickTableRow: PropTypes.func,
};
