import { combineReducers } from 'redux';
import { authReducer } from '@tkww/auth';
import { dashboardReducer } from '@tkww/dashboard';
import { sharedReducer } from '@tkww/shared';
import { feedbackReducer } from '@tkww/feedback';
import { projectReducer } from '@tkww/project';
import { reportReducer } from '@tkww/report';
import { settingReducer } from '@tkww/settings';

const rootReducer = combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  shared: sharedReducer,
  feedback: feedbackReducer,
  project: projectReducer,
  report: reportReducer,
  setting: settingReducer,
});

export default (state, action) => rootReducer(state, action);
