import React from 'react';
import Pagination from '@material-ui/lab/Pagination';
import PropTypes from 'prop-types';
import style from './style.scss';

const PaginationRounded = ({ handleChange, count }) => {
  const onChange = (event, page) => {
    handleChange(page);
  };

  return (
    <div className={style['pagination-container']}>
      <Pagination
        count={count}
        onChange={onChange}
        shape="rounded"
        showFirstButton
        showLastButton
        hideNextButton
        hidePrevButton
      />
    </div>
  );
};

PaginationRounded.propTypes = {
  count: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default PaginationRounded;
